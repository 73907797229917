import React, { PropsWithChildren, ReactElement } from 'react'
import * as Sentry from '@sentry/nextjs'

export enum SentryTagType {
  browserName = 'browser.name',
  environment = 'environment',
  os = 'os',
  level = 'level',
  browser = 'browser',
  handled = 'handled',
  osName = 'os.name',
  mechanism = 'mechanism',
  user = 'user',
  url = 'url',
}

type SentryBoundaryProps = any

export function ErrorBoundary({
  children,
}: PropsWithChildren<SentryBoundaryProps>): ReactElement {
  if (process.env.NEXT_PUBLIC_BUILD_ENV !== 'development')
    return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>
  else return children
}
