import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'

export default class BasicApollo {
  createApolloClient(): ApolloClient<NormalizedCacheObject> {
    throw new Error('need to override createApolloClient function')
  }

  async resetApollo(): Promise<void> {
    throw new Error('need to override reset function')
  }

  async query<TData = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, TData>
  ): Promise<ApolloQueryResult<TData>> {
    throw new Error('need to override query function')
  }

  getClient(initialState?: any | null): ApolloClient<NormalizedCacheObject> {
    throw new Error('need to override getClient function')
  }
}
