import { useBackHandler } from '@kakaomobility/tui-react-maas/dist'
import appBridge from '@kakaomobility/app-bridge'

const useModalBackHandler = (onBack: () => void): void => {
  return useBackHandler(() => {
    if (appBridge.getAppInfo().isAndroid) {
      onBack()
      return true
    }
    return false
  }, appBridge)
}

export default useModalBackHandler
