import React, { ReactElement } from 'react'
import {
  customLogger,
  CUSTOM_LOGGER_PROPS_KEY,
  CUSTOM_APP_LOGGER_PROPS_KEY,
} from '@/helpers/custom-logger'
import { AppPropsWithParams } from '@/router'
import Script from 'next/script'

export default function CustomLoggerDebugger({
  appProps,
}: {
  appProps: AppPropsWithParams
}): ReactElement | null {
  const handleOnLoad = (): void => {
    // @ts-expect-error
    // eslint-disable-next-line no-new
    new window.VConsole({})
    const loggerState = [
      ...(appProps?.pageProps?.[CUSTOM_APP_LOGGER_PROPS_KEY] ?? []),
      ...(appProps?.pageProps?.[CUSTOM_LOGGER_PROPS_KEY] ?? []),
    ]
    customLogger.setLoggingStack(loggerState).printAll()
  }
  return (
    <Script
      src='https://t1.kakaocdn.net/kakaomobility/carowner_web/assets/lib/vconsole.min.js'
      onLoad={handleOnLoad}
    />
  )
}
