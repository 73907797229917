import { useEffect } from 'react'
import { useRouter, PageNotSupport } from '@/router'

const useNotSupportGuard = (): void => {
  const router = useRouter()
  // TODO: Kakaoagent type 대비
  useEffect(() => {
    if (typeof window === 'undefined') return /* guard */
    // @ts-expect-error
    if (window?.KakaoAgent) return /* guard */
    // @ts-expect-error
    const Browser = window?.KakaoAgent?.Browser
    if (!Browser) return /* guard */
    // @ts-expect-error
    const browser = window?.KakaoAgent?.getAgent()?.browser
    // @ts-expect-error
    const isMobile = window?.KakaoAgent?.getAgent()?.isMobile
    if (
      (!isMobile &&
        browser !== Browser.Edge &&
        browser !== Browser.Chrome &&
        browser !== Browser.Safari &&
        browser !== Browser.FireFox) ||
      browser === Browser.InternetExplorer
    ) {
      router.push(PageNotSupport)
    }
  }, [])
}

export default useNotSupportGuard
