import { createSelector } from '@reduxjs/toolkit'
import { UserState } from '@/store/modules/user'
import { ReducerType } from '@/store/reducer'
import { TUserStatus } from '@/__generated__/globalTypes'

export const userInfoSelector = (store: ReducerType): UserState =>
  store.user.userInfo

export const isLoggedInSelector = createSelector(
  [userInfoSelector],
  (userInfo) => {
    return userInfo.state === TUserStatus.NORMAL
  }
)
