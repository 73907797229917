import { userInfoSelector } from '@/store/selectors/user'
import { DebugButton, useToast } from '@kakaomobility/tui-react-maas/dist/maas'
import React from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useSelector } from 'react-redux'

export default function UserInfoButton(): ReactElement {
  const userInfo = useSelector(userInfoSelector)
  const toast = useToast()
  return (
    <DebugButton
      onClick={() => {
        console.log(userInfo)
        toast.show('콘솔을 확인하세요')
      }}
    >
      {userInfo.id > 0 ? userInfo.id : '유저정보 없음'}
    </DebugButton>
  )
}
