import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import {
  getAppInfo,
  renewAccessToken,
  renewTAuthToken,
} from '@kakaomobility/app-bridge'
import { getCookie, setCookie } from '@/helpers/cookieHelper'
import { Cookie, decodeDeviceInfo, RoutePaths } from '@/shared'
import { isServerSide } from '@kakaomobility/tui-react-maas'
import serverContext from '@/services/serverContext'

type FailedRequest = () => void

interface Instance {
  instance: AxiosInstance
  isTokenRefreshing: boolean
  failedRequestQueue: FailedRequest[]
  onError: (e: AxiosError) => Promise<unknown>
  onRequest: (config: AxiosRequestConfig) => AxiosRequestConfig
  getInstance: () => AxiosInstance
}

class ServerInstance implements Instance {
  instance = axios.create({
    baseURL: 'http://localhost:3035',
    withCredentials: true,
  })

  isTokenRefreshing = false

  failedRequestQueue: FailedRequest[] = []

  async onError(e: AxiosError): Promise<void> {
    throw new Error(e.message)
  }

  onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    const data = serverContext.getContext()
    const headers = config.headers ? config.headers : {}
    headers[Cookie.TAUTH_ACCESS_TOKEN] = data?.[Cookie.TAUTH_ACCESS_TOKEN]
    headers[Cookie.ACCESS_TOKEN] = data?.[Cookie.ACCESS_TOKEN]
    headers.Cookie = data?.cookie
    config.headers = headers
    return config
  }

  getInstance(): AxiosInstance {
    this.instance.interceptors.request.use(
      this.onRequest.bind(this),
      this.onError.bind(this)
    )
    this.instance.interceptors.response.use(undefined, this.onError.bind(this))
    return this.instance
  }
}

class ClientInstance implements Instance {
  instance = axios.create({
    baseURL: '/',
    withCredentials: true,
  })

  isTokenRefreshing = false

  failedRequestQueue: FailedRequest[] = []

  async onError(e: AxiosError): Promise<unknown> {
    const { response, config } = e
    if (response?.status !== 401) return await Promise.reject(e)

    /** 토큰 갱신 후 재시도에도 다시 에러가 발생한 경우 */
    if ((config as AxiosRequestConfig & { _retry?: boolean })._retry) {
      return await Promise.reject(e)
    }

    try {
      if (this.isTokenRefreshing) {
        const originalRequest = { ...config, _retry: true }
        const retryOriginalRequest = new Promise((resolve) => {
          this.failedRequestQueue.push(() => {
            resolve(this.instance(originalRequest))
          })
        })

        return await retryOriginalRequest
      }

      this.isTokenRefreshing = true
      //* * renew AccessToken */
      if (renewAccessToken.isSupported()) {
        setCookie(Cookie.ACCESS_TOKEN, await renewAccessToken())
      } else {
        throw new Error(
          `renewAccessToken is not supported: ${JSON.stringify(getAppInfo())}`
        )
      }
      if (renewTAuthToken.isSupported()) {
        setCookie(Cookie.TAUTH_ACCESS_TOKEN, await renewTAuthToken())
      } else {
        throw new Error(
          `renewTAuthToken is not supported: ${JSON.stringify(getAppInfo())}`
        )
      }
      await this.instance.get(
        `${process.env.NEXT_PUBLIC_PUBLIC_URI}/${RoutePaths.USER_ME}`
      )
      this.failedRequestQueue.forEach((failedRequest) => {
        failedRequest()
      })
    } catch (e: any) {
      // TODO: 로그인 정보를 지워주는게 좋을 것 같음. 우선 앱일 경우만 401로 보내기
      const deviceInfoCookie = getCookie(Cookie.DEVICE_INFO)
      if (decodeDeviceInfo(deviceInfoCookie)?.kakaoTApp ?? false) {
        window.location.pathname = '/error'
      }
      console.error(e)
      return await Promise.reject(e?.response || e)
    } finally {
      this.isTokenRefreshing = false
    }
  }

  onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    return config
  }

  getInstance(): AxiosInstance {
    this.instance.interceptors.response.use(undefined, this.onError.bind(this))
    return this.instance
  }
}

const instance = isServerSide()
  ? new ServerInstance().getInstance()
  : new ClientInstance().getInstance()

export default instance
