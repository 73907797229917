import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**
 * @description tiara 전용 스토어
 * 티아라 정보를 위해 저장해야할 공간이 필요할때 쓰는 slice
 */

const initialState: { sentFrom: string } = {
  sentFrom: '',
}

export const tiaraSlice = createSlice({
  name: 'tiara',
  initialState,
  reducers: {
    setSentFrom(state, action: PayloadAction<string>) {
      state.sentFrom = action.payload
    },
    resetTiaraSaveData(state) {
      state.sentFrom = ''
    },
  },
})

export const { setSentFrom, resetTiaraSaveData } = tiaraSlice.actions
