import UAParser from 'ua-parser-js'
import { KAKAO_T_APP, KAKAOTALK_APP } from '@/shared/regexp/kakao'

export interface DeviceInfo {
  os: string | null
  osVersion: string | null
  browser: string | null
  browserVersion: string | null
  kakaoTApp: boolean
  kakaoTAppVersion: string | null
  kakaoTalkApp: boolean
  kakaoTalkAppVersion: string | null
  uaString: string | null
}

export const parseUserAgent = (
  uaString?: string,
  testCookies?: string
): DeviceInfo | null => {
  const testUserAgent =
    'Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148/KakaoTApp v5.15.0'
  const userAgentInfo = testCookies ? testUserAgent : uaString
  if (!userAgentInfo) return null /* guard */
  const parsedResult = new UAParser(userAgentInfo).getResult()
  return {
    os: parsedResult?.os?.name?.toLowerCase() ?? null,
    osVersion: parsedResult?.os?.version ?? null,
    browser: parsedResult?.browser?.name?.toLowerCase() ?? null,
    browserVersion: parsedResult?.browser?.version ?? null,
    kakaoTApp: KAKAO_T_APP.test(userAgentInfo),
    kakaoTAppVersion: KAKAO_T_APP.exec(userAgentInfo)?.[1] ?? null,
    kakaoTalkApp: KAKAOTALK_APP.test(userAgentInfo),
    kakaoTalkAppVersion: KAKAOTALK_APP.exec(userAgentInfo)?.[1] ?? null,
    uaString: userAgentInfo ?? null,
  }
}
