import { isServerSide } from '@kakaomobility/tui-react-maas'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'
import BasicApollo from '@/apollo/apollo-proxy/BasicApollo'
import ApolloServerSide from '@/apollo/apollo-proxy/ApolloServerSide'
import ApolloClientSide from '@/apollo/apollo-proxy/ApolloClientSide'

export const { useApolloNetworkStatus } = createNetworkStatusNotifier()

/**
 * @description
 * apollo 에서 cache 자체를 hydration 하기 위해 생성한 proxy
 * @example
 * https://medium.com/@zhamdi/server-side-rendering-ssr-using-apollo-and-next-js-ac0b2e3ea461
 */
export default class ApolloProxy extends BasicApollo {
  private readonly apolloController: BasicApollo

  constructor() {
    super()
    if (isServerSide()) this.apolloController = new ApolloServerSide()
    else this.apolloController = new ApolloClientSide()
  }

  createApolloClient(): ApolloClient<NormalizedCacheObject> {
    return this.apolloController.createApolloClient()
  }

  async query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ): Promise<ApolloQueryResult<T>> {
    return await this.apolloController.query(options)
  }

  async resetApollo(): Promise<void> {
    await this.apolloController.resetApollo()
  }

  getClient(initialState?: any | null): ApolloClient<NormalizedCacheObject> {
    return this.apolloController.getClient(initialState)
  }
}
