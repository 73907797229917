import { createSelector } from '@reduxjs/toolkit'
import { ReducerType } from '@/store/reducer'
import { DeviceState } from '@/store/modules/device'
import { Browser, MobileOS } from '@/shared'

export const deviceStateSelector = (store: ReducerType): DeviceState =>
  store.device

export const deviceInfoSelector = createSelector(
  [deviceStateSelector],
  (deviceState) => {
    return deviceState.deviceInfo
  }
)

export const uaStringSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.uaString
  }
)

export const isIOSSelctor = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.os === MobileOS.IOS
  }
)

export const isAndroidSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.os === MobileOS.ANDROID
  }
)

export const osVersionSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.osVersion
  }
)

export const browserSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.browser
  }
)

export const browserVersionSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.browserVersion
  }
)

export const isIESelector = createSelector([browserSelector], (browser) => {
  return browser === Browser.IE
})

export const isKakaoTSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return Boolean(deviceInfo?.kakaoTApp)
  }
)

export const tVersionSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.kakaoTAppVersion
  }
)

export const isKakaoTalkSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return Boolean(deviceInfo?.kakaoTalkApp)
  }
)

export const talkVersionSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return deviceInfo?.kakaoTalkAppVersion
  }
)

export const isMobileSelector = createSelector(
  [deviceInfoSelector],
  (deviceInfo) => {
    return (
      deviceInfo?.os === MobileOS.IOS ||
      deviceInfo?.os === MobileOS.ANDROID ||
      Boolean(deviceInfo?.kakaoTApp) ||
      Boolean(deviceInfo?.kakaoTalkApp)
    )
  }
)
