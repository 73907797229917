import {
  LocationInput,
  RentType,
  RouteType,
  ShuttleGrade,
  TemporalSpotInput,
} from '@/__generated__/globalTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { KakaoPlaceSearchResult } from '@ditto/kakao-sdk-manager'
import { TimeFormat } from '@kakaomobility/tui-react-maas/dist/maas'

export interface SelectedSpot {
  address: string
  name: string
}

interface CharterNewQueryParamProps {
  openType: string | null
  promotionCode: string | null
}

interface CreateOpenShuttleVariablesProps {
  title: string
  routeType: RouteType
  rentType: RentType
  tags: string
  shuttleGrade: ShuttleGrade | null
  departureDate: string
  departureTime?: TimeFormat
  returnDepartureDate?: string | null
  returnDepartureTime?: TimeFormat | null
  departureSpot?: TemporalSpotInput
  arrivalSpot?: TemporalSpotInput
  remark?: string | null
  quantity: number
  seater: number | null
  searchResult?: {
    keyword: string
    results: KakaoPlaceSearchResult[]
  }
  location: LocationInput
  selectedSpot: SelectedSpot
  /** 상품 선택시 태그 */
  selectedTags: string[] | null
}

export type CharterProductState = CreateOpenShuttleVariablesProps &
  CharterNewQueryParamProps

const initialState: CharterProductState = {
  promotionCode: null,
  arrivalSpot: undefined,
  departureDate: '',
  departureSpot: undefined,
  departureTime: undefined,
  quantity: 1,
  rentType: RentType.CROWD,
  returnDepartureDate: undefined,
  returnDepartureTime: undefined,
  routeType: RouteType.ROUND_TRIP,
  seater: null,
  shuttleGrade: null,
  tags: '',
  title: '',
  remark: '',
  searchResult: undefined,
  location: {
    longitude: 0,
    latitude: 0,
  },
  selectedSpot: {
    name: '',
    address: '',
  },
  openType: null,
  selectedTags: null,
}

export const charterProductSlice = createSlice({
  name: 'charterProduct',
  initialState,
  reducers: {
    reset(state) {
      Object.keys(state).forEach((key) => {
        // eslint-disable-next-line
        // @ts-ignore
        state[key as keyof CharterProductState] =
          initialState[key as keyof CharterProductState]
      })
    },
    setPromotionCode(state, action: PayloadAction<string | null>) {
      state.promotionCode = action.payload
    },
    resetDateTime(state) {
      state.departureDate = ''
      state.departureTime = undefined
      state.returnDepartureDate = undefined
      state.returnDepartureTime = undefined
    },
    setRentType(state, action: PayloadAction<RentType>) {
      state.rentType = action.payload
    },
    setRouteType(state, action: PayloadAction<RouteType>) {
      state.routeType = action.payload
    },
    setShuttleGrade(state, action: PayloadAction<ShuttleGrade | null>) {
      state.shuttleGrade = action.payload
    },
    setSeater(state, action: PayloadAction<number | null>) {
      state.seater = action.payload
    },
    setDepartureDate(state, action: PayloadAction<string>) {
      state.departureDate = action.payload
    },
    setDepartureTime(state, action: PayloadAction<TimeFormat | undefined>) {
      state.departureTime = action.payload
    },
    setReturnDepartureDate(state, action: PayloadAction<string | undefined>) {
      state.returnDepartureDate = action.payload
    },
    setReturnDepartureTime(
      state,
      action: PayloadAction<TimeFormat | undefined>
    ) {
      state.returnDepartureTime = action.payload
    },
    setDepartureSpot(state, action: PayloadAction<TemporalSpotInput>) {
      state.departureSpot = action.payload
    },
    setArrivalSpot(state, action: PayloadAction<TemporalSpotInput>) {
      state.arrivalSpot = action.payload
    },
    setQuantity(state, action: PayloadAction<number>) {
      state.quantity = action.payload
    },
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload
    },
    setTags(state, action: PayloadAction<string>) {
      state.tags = action.payload
    },
    setRemark(state, action: PayloadAction<string>) {
      state.remark = action.payload
    },
    setSearchResult(
      state,
      action: PayloadAction<{
        keyword: string
        results: KakaoPlaceSearchResult[]
      }>
    ) {
      state.searchResult = action.payload
    },
    resetSearchResult(state) {
      state.searchResult = undefined
    },
    setLocation(state, action: PayloadAction<LocationInput>) {
      state.location = action.payload
    },
    resetLocation(state) {
      state.location.longitude = 0
      state.location.latitude = 0
    },
    setSelectedSpot(state, action: PayloadAction<SelectedSpot>) {
      state.selectedSpot = action.payload
    },
    resetSelectedSpot(state) {
      state.selectedSpot = initialState.selectedSpot
    },
    setOpenType(state, action: PayloadAction<string | null>) {
      state.openType = action.payload
    },
    setRecentSearch(
      state,
      action: PayloadAction<{
        routeType: RouteType
        departureSpot?: TemporalSpotInput
        departureDate: string
        departureTime?: TimeFormat
        arrivalSpot?: TemporalSpotInput
        returnDepartureDate?: string | null
        returnDepartureTime?: TimeFormat | null
      }>
    ) {
      state.routeType = action.payload.routeType
      state.departureSpot = action.payload.departureSpot
      state.departureDate = action.payload.departureDate
      state.departureTime = action.payload.departureTime
      state.arrivalSpot = action.payload.arrivalSpot
      state.returnDepartureDate = action.payload.returnDepartureDate
      state.returnDepartureTime = action.payload.returnDepartureTime
    },
  },
})

/* actions */
export const {
  reset,
  resetDateTime,
  setRentType,
  setRouteType,
  setShuttleGrade,
  setSeater,
  setDepartureDate,
  setDepartureTime,
  setReturnDepartureDate,
  setReturnDepartureTime,
  setDepartureSpot,
  setArrivalSpot,
  setQuantity,
  setTitle,
  setTags,
  setRemark,
  setSearchResult,
  resetSearchResult,
  setLocation,
  resetLocation,
  setSelectedSpot,
  resetSelectedSpot,
  setOpenType,
  setRecentSearch,
  setPromotionCode,
} = charterProductSlice.actions
