export enum ShuttleDialogType {
  CLIPBOARD_COPIED = 'clipboardCopied',
  BOOKING_SUCCESS = 'bookingSuccess',
  DATE_PICKER = 'datePicker',
  TIME_PICKER = 'timePicker',
  LOCATION_MAP_MODAL = 'locationMapModal',
  SHUTTLE_DATE_TIME_RESET_MODAL = 'shuttleDateTimeResetModal',
  ADJUSTED_LOCATION_SELECTED_MODAL = 'adjustedLocationSelectedModal',
}

export enum ShuttleItemType {
  OPEN_SHUTTLE_ITEM = '오픈셔틀',
  T_SHUTTLE_ITEM = 'T 셔틀',
  RENT_BUS_ITEM = '전체대절',
}

export enum ShuttleRouteType {
  ONE_WAY = '편도',
  ROUND_TRIP = '왕복',
}

export enum PageShuttleNewView {
  ESTIMATION = 'estimation',
  CALCULATION = 'calculation',
  CHECKOUT = 'checkout',
}

export enum PageBookingNewView {
  SCHEDULE = 'schedule',
  SEAT = 'seat',
  CHECKOUT = 'checkout',
}

export enum PageOrderItemModule {
  PAYMENT = 'payment',
}

export enum PageUnpaidItemModule {
  PAYMENT = 'payment',
}

export enum TextFieldMaxLengthType {
  MAX_LENGTH_40 = 40,
  MAX_LENGTH_100 = 100,
}

export enum BookingVerticalPath {
  SHUTTLES = 'shuttles',
  RENTBUS = 'rent-bus',
}

export enum ShuttleProductDateTime {
  MIN_DEPARTURE_DATE = 8,
  CROWD_MAX_DEPARTURE_DATE = 90,
  CHARTER_MAX_DEPARTURE_DATE = 90,
  DEFAULT_MIN_TIME = '00:00:00',
  DEFAULT_MAX_TIME = '23:59:59',
  INTERVAL = 10,
}

export enum BookingVertical {
  T_SHUTTLE = 'T_SHUTTLE',
  SHUTTLE = 'SHUTTLE',
  RENTBUS = 'RENTBUS',
}

export enum AccountType {
  KAKAOT = 'KAKAOT',
}

export enum PaymentMethodType {
  CARD = 'CARD',
}

export enum SeatInventoryType {
  FREE_SEAT = '자유석',
}

export enum LocaleType {
  LOCALE_KO = 'ko',
  LOCALE_ENGB = 'en-us',
  LOCALE_JA = 'ja',
}

export enum DateHelperType {
  SIMPLE_DATETIME_WITH_DASH = 'YYYY-MM-DD',
  SIMPLE_DATETIME_DOT_WITH_YEAR = 'YYYY.MM.DD (ddd)',
  SIMPLE_TIME = 'HH:mm',
  SIMPLE_TIME_WITH_SECOND = 'HH:mm:ss',
  SIMPLE_DATETIME_DOT_WITH_YEAR_AND_TIME = 'YYYY.MM.DD (ddd) HH:mm',
  YEAR_MONTH = 'YYYY.MM',
  ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm',
  YY_MM_DD_d = 'YY.MM.DD(ddd)',
  MM_DD_d = 'MM.DD(ddd)',
  MM_DD_d_HH_mm = 'MM.DD(ddd) HH:mm',
  MM_DD = 'MM.DD',
  YY_MM_DD_d_mm = 'YY.MM.DD(ddd) HH:mm',
  MMM_Do = 'MMM Do',
  HH_mm = 'HH:mm',
}

export enum MomentUnitType {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAY = 'day',
}

export enum TimeArrayType {
  MINUTE = 'mm',
  HOUR = 'HH',
}

export enum ErrorCodeType {
  PARAMETER = 400,
  UN_AUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  SERVER = 500,
}

export enum ErrorButtonType {
  HOME = 'home',
  T_APP_INSTALL = 'tAppInstall',
  CONTACT_CS = 'contactCS',
}

export enum ErrorRedirectMethod {
  PUSH,
  REPLACE,
}

export interface PaymentWebOptionParams {
  productCode: 'SHUTTLE'
  paymentType: 'T_SHUTTLE_FARE'
  payAmount: string | number
  redirectUrl: string
  isUnpaid?: boolean
  lastPaymentType?: 'TPOINT' | 'CARD'
  lastPaymentKey?: string
  useResponsive?: boolean
  breakpoint?: string | number
  isApp?: boolean
  useFullscreen?: boolean
  lang?: 'ko' | 'en'
}

export interface StylePosition {
  top?: number | string
  left?: number | string
  bottom?: number | string
  right?: number | string
}
