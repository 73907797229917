/* dependencies */
import { NextRouter } from 'next/router'
import assert from 'assert'

export interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

export interface UrlObject<T> {
  auth?: string | null
  hash?: string | null
  host?: string | null
  hostname?: string | null
  href?: string | null
  pathname?: string | null
  protocol?: string | null
  search?: string | null
  slashes?: boolean | null
  port?: string | number | null
  query?: string | null | T
}

/**
 * @description next router를 사용할때 path에 따라 query를 type 지정하기 위해 타입 재정의
 * @example
 * interface PageMainQuery {
 *   hello: string;
 * }
 * const PageMain = new Route<PageMainQuery>('/main')
 * router.push(PageMain, { hello: 'world' }) // good!
 * router.push(PageMain, { world: 'hello' }) // error!
 */
export interface ExtendedNextRouter extends NextRouter {
  push: <T extends Route<any, any>>(
    url:
      | string
      | UrlObject<T extends Route<any, infer QueryType> ? QueryType : never>,
    as?: UrlObject<T extends Route<any, infer QueryType> ? QueryType : never>,
    options?: TransitionOptions
  ) => Promise<boolean>
  replace: <T extends Route<any, any>>(
    url:
      | string
      | UrlObject<T extends Route<any, infer QueryType> ? QueryType : never>,
    as?: UrlObject<T extends Route<any, infer QueryType> ? QueryType : never>,
    options?: TransitionOptions
  ) => Promise<boolean>
  query: {
    [key: string]: string | undefined
  }
}

/**
 * @description
 * 일단 pathname을 기본 지정자로 생각하기 때문에 pathname만 정의를 해줌. 추후 확장 가능
 */
export default class Route<ParamType extends any, QueryType extends any>
  implements UrlObject<QueryType>
{
  pathname: string = ''

  constructor(pathname: string) {
    this.pathname = pathname
  }

  private shouldObject(element: any): element is Object {
    return typeof element === 'object' && !Array.isArray(element)
  }

  private parseParams(pathname: string, params?: ParamType): string | never {
    let temp = pathname
    if (!params) return temp /* guard */
    assert(this.shouldObject(params), 'Params should be object') /* guard */
    Object.entries(params).forEach(([key, value]) => {
      if (this.pathname.includes(key))
        temp = temp.replace(`:${key}`, `${value}`)
    })
    return temp
  }

  private parseQuery(query?: QueryType): string[] | never {
    const search: string[] = []
    if (!query) return search /* guard */
    assert(this.shouldObject(query), 'Query should be object') /* guard */
    Object.entries(query).forEach(([key, value]) => {
      if (value === undefined || value === null || value === 'undefined')
        return /* guard */
      search.push(`${key}=${value as any}`)
    })
    return search
  }

  /**
   * @description 주소로 바꿔주는 함수
   * @example
   * const PageNotice = new Route<{ noticeId: string }>('/notice/:noticeId')
   * PageNotice.toString({ noticeId: 'hello', search: 'world' }) -> /notices/hello?world=world
   */
  toString({
    params,
    query,
  }: { params?: ParamType; query?: QueryType } = {}): string {
    const parsedPathname = this.parseParams(this.pathname, params)
    const search = this.parseQuery(query)
    return search.length === 0
      ? parsedPathname
      : `${parsedPathname}?${search.join('&')}`
  }
}
