import React, { PropsWithChildren, ReactElement } from 'react'
import styled from '@emotion/styled'
import { FullViewLoading } from '@kakaomobility/tui-react-maas/dist/maas'

const Styled = {
  loader: styled(FullViewLoading)`
    z-index: 10000;
  `,
}

interface LoaderProps {
  show?: boolean
}

function Loader({ show = true }: PropsWithChildren<LoaderProps>): ReactElement {
  return <Styled.loader show={show} dimmed={true} />
}

export default Loader
