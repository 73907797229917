import React, { ReactElement, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useRouter } from '@/router'
import { useApolloNetworkStatus } from '@/apollo'
import Loader from '@/components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingSelector } from '@/store/selectors/loader'
import { setLoaded, setLoading } from '@/store/modules/loader'

function KakaoMobilityLoader(): ReactElement | null {
  if (typeof window === 'undefined') return null /* guard */
  const router = useRouter()
  const dispatch = useDispatch()
  const isLoading = useSelector(isLoadingSelector)

  /**
   * @description 라우트가 이동할때 로딩창 생성
   */
  useEffect(() => {
    const handleStart = (): void => {
      dispatch(setLoading())
    }
    const handleComplete = (): void => {
      dispatch(setLoaded())
    }
    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)
    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [dispatch])

  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus()
  const show = numPendingQueries > 0 || numPendingMutations > 0
  return createPortal(
    <Loader show={isLoading || show} />,
    window.document.body
  ) as ReactElement
}

export default KakaoMobilityLoader
