import { STYLE_MOMENTUM_SCROLL } from '@/styles/presets'
import styled from '@emotion/styled'
import { TColors } from '@kakaomobility/tui-core'

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${TColors.NEUTRAL2};
  ${STYLE_MOMENTUM_SCROLL};
`
