import { HYDRATE } from 'next-redux-wrapper'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeviceInfo } from '@/helpers/user-agent'

export interface DeviceState {
  hydratedFlag: boolean
  deviceInfo?: DeviceInfo | null
}

const initialState: DeviceState = {
  hydratedFlag: false,
  deviceInfo: undefined,
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDeviceInfo(state, action: PayloadAction<DeviceInfo | null>) {
      state.deviceInfo = action.payload
    },
    resetDeviceInfo(state) {
      state.deviceInfo = initialState.deviceInfo
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: { type: any; payload: any }) => {
      if (state.hydratedFlag) return /* guard */
      return {
        ...state,
        ...action.payload.device,
        hydratedFlag: true,
      }
    })
  },
})

/* actions */
export const { setDeviceInfo } = deviceSlice.actions
