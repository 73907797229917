import {
  UnpaidItem,
  UnpaidListResponse,
} from '@/services/hooks/queries/unpaid/useUnpaidList'
import instance from '@/services/instance'
import { ProxyPath } from '@/shared'
import { KMTPointServiceUserReturn } from '@/services/hooks/queries/accounts/useGetKMTPoint'
import {
  BookingsParams,
  BookingsResponse,
} from '@/services/hooks/queries/charter-reservation/useCreateBookings'
import {
  CollectUnpaidParams,
  CollectUnpaidResponse,
} from '@/services/hooks/mutations/unpaid/useCollectUnpaid'
import { BookingVertical } from '@/enums'

export const getUnpaidListShuttle = async (): Promise<UnpaidItem[]> => {
  return (
    await instance.post(
      `${ProxyPath.WEBVIEW_GATEWAY}/v1/shuttle-2/bookings/T_SHUTTLE/unpaid-list`
    )
  )?.data?.reduce((acc: UnpaidItem[], cu: UnpaidItem) => {
    cu.verticalType = 'SHUTTLE'
    return [...acc, cu]
  }, [])
}

export const getUnpaidListCharterBus =
  async (): Promise<UnpaidListResponse> => {
    return (
      await instance.get(
        `${ProxyPath.WEBVIEW_GATEWAY}/v1/shuttle/bookings/payment/${BookingVertical.RENTBUS}/unpaids`
      )
    )?.data
  }

export const getKakaoTPoint = async (
  paymentAmount: number | string
): Promise<KMTPointServiceUserReturn> => {
  return (
    await instance.get(
      `${ProxyPath.WEBVIEW_GATEWAY}/v1/billing/point/tpoint/SHUTTLE?paymentAmount=${paymentAmount}`
    )
  )?.data
}

export const createBookings = async (
  params: BookingsParams
): Promise<BookingsResponse> => {
  const { isRentBus, ...restParams } = params
  if (isRentBus)
    return (
      await instance.post(
        `${ProxyPath.WEBVIEW_GATEWAY}/v2/shuttle/bookings`,
        restParams
      )
    )?.data
  return (
    await instance.post(
      `${ProxyPath.WEBVIEW_GATEWAY}/v1/shuttle/bookings`,
      restParams
    )
  )?.data
}

export const collectUnpaid = async (
  params: CollectUnpaidParams
): Promise<CollectUnpaidResponse> => {
  const { paymentId, request } = params
  return (
    await instance.post(
      `${ProxyPath.WEBVIEW_GATEWAY}/v1/billing/payment/unpaid/payments/${paymentId}/collect`,
      request
    )
  )?.data
}
