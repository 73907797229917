import { createSlice } from '@reduxjs/toolkit'

/**
 * @description loading을 관리하는 store
 * 여러 로딩이 있을 수 있기 때문에 stack 자료구조를 택함.
 * stack에 length에 따라 loading or loaded 상태 변경
 */

const initialState: { loadingStack: number[] } = {
  loadingStack: [],
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading(state) {
      state.loadingStack.push(1)
    },
    setLoaded(state) {
      state.loadingStack.pop()
    },
  },
})

export const { setLoading, setLoaded } = loaderSlice.actions
