import * as Sentry from '@sentry/nextjs'

if (process.env.NEXT_PUBLIC_BUILD_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_BUILD_ENV,
    ignoreErrors: [
      'ChunkLoadError',
      'Loading chunk',
      'Loading CSS chunk',
      'Uncaught Sentry Error',
    ],
    beforeSend(event: Sentry.Event) {
      const { tags, request } = event
      const isChunkLoadError = tags?.extra === 'chunkLoadError'
      const isRetry = request?.url?.indexOf('#retry') !== -1
      if (!isChunkLoadError) return event
      if (isRetry) return event
      return null
    },
  })
}
