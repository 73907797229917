import { useCallback } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { PageUnpaidItem, useRouter } from '@/router'
import {
  getUnpaidListCharterBus,
  getUnpaidListShuttle,
} from '@/services/api/gateway'
import { BusLine } from '@/services/models/common'

export interface UnpaidListResponse {
  content: UnpaidItem[]
}

type UseUnpaidList = UseQueryResult<UnpaidListResponse, AxiosError> & {
  unpaidList?: UnpaidItem[]
  unpaidItem?: UnpaidItem
  handleRedirectToUnpaidItem: () => void
  isUnpaid: boolean
}

export interface UnpaidBookings {
  create_at: string
  destination: string
  end_date_time: string
  start_date_time: string
  /* 셔틀 용 */
  busLines: BusLine[]
  endDateTime: string
  id: number
  order_id: string
  origin: string
  startDateTime: string
  title: string
  update_at: string
}

export interface UnpaidItem {
  wayPoints?: string
  autopay_card_key: string
  bookings: UnpaidBookings[]
  fare: number
  id: number
  reason: string
  nickname?: string

  /* for shuttle only */
  verticalType?: 'SHUTTLE'
}

interface UseUnpaidListFetcherOptions {
  enabled?: boolean
}

function useUnpaidList({
  enabled,
}: UseUnpaidListFetcherOptions): UseUnpaidList {
  const router = useRouter()

  const queryResult = useQuery<UnpaidListResponse, AxiosError>(
    'useUnpaidList',
    async () => {
      const [shuttleUnpaid, rentBusUnpaid] = await Promise.all([
        getUnpaidListShuttle(),
        getUnpaidListCharterBus(),
      ])
      return {
        content: [
          ...((shuttleUnpaid as any) ?? []),
          ...((rentBusUnpaid as any)?.content ?? []),
        ],
      }
    },
    { enabled }
  )

  const { content: unpaidItem } = queryResult.data ?? {}

  const handleRedirectToUnpaidItem = useCallback((): void => {
    if (!unpaidItem?.length) return
    const data = unpaidItem[0]
    router.push(PageUnpaidItem.toString({ params: { callId: data.id } }))
  }, [unpaidItem])

  return {
    ...queryResult,
    unpaidList: unpaidItem,
    unpaidItem: unpaidItem?.[0],
    isUnpaid: !!unpaidItem?.length,
    handleRedirectToUnpaidItem,
  }
}

export default useUnpaidList
