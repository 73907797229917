import { ApolloError } from 'apollo-boost'
import { GraphQLError } from 'graphql/error'
import * as Sentry from '@sentry/react'

export type ExpectedError =
  | string
  | ApolloError
  | GraphQLError
  | GraphQLError[]
  | readonly GraphQLError[]
  | Error
  | undefined

export function errorMessage(error: ExpectedError): string | undefined {
  if (!error) return
  Sentry.setContext('error', { content: JSON.stringify(error) })
  Sentry.captureException(error)

  if (typeof error === 'string') return error

  if ((error as GraphQLError[])?.length) {
    return (error as GraphQLError[]).reduce<string>((acc, cur) => {
      acc = `${acc}${cur?.message ?? ''}`
      return acc
    }, '')
  }

  return (
    (error as ApolloError)?.graphQLErrors?.[0]?.message ??
    (error as ApolloError)?.networkError?.message ??
    (error as Error)?.message ??
    '알 수 없는 오류가 발생했습니다.'
  )
}
