import { ReactElement, PropsWithChildren } from 'react'
import { ApolloProvider as Provider } from '@apollo/client'
import { useApollo } from '@/apollo/useApollo'

export default function ApolloProvider({
  children,
  appProps,
}: PropsWithChildren<any>): ReactElement {
  const client = useApollo(appProps?.pageProps)
  return <Provider client={client}>{children}</Provider>
}
