import React, { ReactElement, useEffect } from 'react'
import { AppProps } from 'next/app'
import { NextPage } from 'next'
import {
  ExtendedNextRouter,
  PageBookingItem,
  PageError,
  PageUnpaidItem,
  useRouter,
} from '@/router'
import useAuthentication from '@/services/hooks/queries/accounts/useAuthentication'
import useErrorRenderHandler from '@/hooks/useErrorRenderHandler'
import useUnpaidList from '@/services/hooks/queries/unpaid/useUnpaidList'
import { ErrorCodeType } from '@/enums'
import useSetUserIdentifier from '@/hooks/useSetUserIdentifier'
import * as Sentry from '@sentry/nextjs'
import AuthGuard from '@/router/AuthGuard'
import { AppState, useAppSelector } from '@/store'

export type CustomNextPage<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (
    page: ReactElement,
    { router, state }: { router: ExtendedNextRouter; state: AppState }
  ) => ReactElement
  authRequired?: boolean
  omitAuth?: boolean
}

export type AppPropsWithParams = AppProps & {
  Component: CustomNextPage
  router: ExtendedNextRouter
  pageProps: any
}

interface RouteInterceptorProps {
  Component: CustomNextPage
}

export default function RouteInterceptor({
  Component,
  ...restProps
}: RouteInterceptorProps): ReactElement {
  const router = useRouter()
  const state = useAppSelector((state) => state)
  const { getLayout } = Component
  const { loggedIn, handleLogin } = useAuthentication()
  useSetUserIdentifier()

  const { handleRedirectToUnpaidItem, isError, isLoading, unpaidList, error } =
    useUnpaidList({
      enabled: loggedIn,
    })
  const { handleErrorFullScreen } = useErrorRenderHandler()

  useEffect(() => {
    if (!isLoading && isError) {
      Sentry.setContext('error', { content: JSON.stringify(error) })
      Sentry.captureException(error)
      if (error?.response?.statusText === 'Unauthorized') handleLogin()
      else if (router.pathname !== PageError.pathname) {
        // TODO: 토큰만료시 인증정보지워지지 않는 동안은 무한루프에 빠질 수 있으므로 수정
        handleErrorFullScreen({
          errorCode: ErrorCodeType.SERVER,
        })
      }
    }
  }, [isLoading, isError, handleErrorFullScreen, handleLogin, error, router])

  useEffect(
    function redirectToUnpaidItem() {
      if (!loggedIn) {
        return
      }
      if (!unpaidList?.length) {
        return
      }
      if (router.pathname === PageUnpaidItem.pathname) {
        return
      }
      if (router.pathname === PageError.pathname) {
        return
      }
      if (router.pathname === PageBookingItem.pathname) {
        return
      }
      if (/\/bookings\/d*/gi.test(router.pathname)) {
        /* 예매 상세로 들어왔을때 */
        return
      }

      handleRedirectToUnpaidItem()
    },
    [handleRedirectToUnpaidItem, loggedIn, unpaidList]
  )

  /**
   * @description 동적 레이아웃 생성을 위한 작업
   * @see https://nextjs.org/docs/basic-features/layouts
   */
  const renderLayout = getLayout ?? ((page) => page)
  return (
    <AuthGuard Component={Component}>
      {renderLayout(<Component {...restProps} />, { router, state })}
    </AuthGuard>
  )
}
