import { Route } from '@/router/routes/index'

interface PageAllCharterNewJourneyQueries {
  dialogType?: string
  locationType?: string
  share?: string
}
export const PageAllCharterNewJourney = new Route<
  never,
  PageAllCharterNewJourneyQueries
>('/all-charter/new/journey')

interface PageAllCharterNewCalculationQueries {
  share?: string
}

export const PageAllCharterNewCalculation = new Route<
  never,
  PageAllCharterNewCalculationQueries
>('/all-charter/new/calculation')

export const PageAllCharterNewExtraInfo = new Route<never, never>(
  '/all-charter/new/extra-info'
)
