import { Route } from '@/router/routes/index'

export interface PageOrderItemParams {
  orderId?: string
}
export interface PageOrderItemQueries {
  vertical?: string
  module?: string
  dialogType?: string
  bookingId?: string
  payAmount?: string
}
export const PageOrderItem = new Route<
  PageOrderItemParams,
  PageOrderItemQueries
>('/orders/:orderId')
