import { useEffect } from 'react'
import { TUserStatus } from '@/__generated__/globalTypes'
import { ErrorButtonType, ErrorCodeType, ErrorRedirectMethod } from '@/enums'
import { useAppSelector } from '@/store'
import useErrorRenderHandler from '@/hooks/useErrorRenderHandler'
import useAuthentication from '@/services/hooks/queries/accounts/useAuthentication'
import { userInfoSelector } from '@/store/selectors/user'
import appBridge from '@kakaomobility/app-bridge'
import { setCookie } from '@/helpers/cookieHelper'
import { useDispatch } from 'react-redux'
import { setUser } from '@/store/modules/user'
import { getUser } from '@/services/api/auth'

export interface AdditionalHeaderForAuth {
  'X-TAUTH-ACCESS-TOKEN'?: string
  'X-KAKAOT-ACCESS-TOKEN'?: string
}

function useAuthenticationGuard(
  enabled: boolean,
  authRequired?: boolean
): void {
  const user = useAppSelector(userInfoSelector)
  const { loggedIn, handleLogin } = useAuthentication()
  const { handleErrorFullScreen } = useErrorRenderHandler()
  const dispatch = useDispatch()

  /**
   * @description 유저의 상태에 따라 대응하는 로직 구성
   */
  useEffect(() => {
    if (!enabled) return
    const { state, bannedReason } = user
    switch (state) {
      // TODO: not_found 라는 계정이 있을 수있는건지? sleeping은 필요 없는 것인지?
      case TUserStatus.NOT_FOUND:
        handleErrorFullScreen({
          method: ErrorRedirectMethod.PUSH,
          errorCode: ErrorCodeType.UN_AUTHORIZED,
          title: '회원 가입이 필요한 서비스입니다',
          message:
            '카카오 T를 설치하여 회원가입 후 서비스를 이용해 주시기바랍니다.',
          buttonType: ErrorButtonType.T_APP_INSTALL,
        })
        break
      case TUserStatus.FORBIDDEN:
        handleErrorFullScreen({
          method: ErrorRedirectMethod.PUSH,
          errorCode: ErrorCodeType.FORBIDDEN,
          buttonType: ErrorButtonType.HOME,
        })
        break
      case TUserStatus.BANNED:
        handleErrorFullScreen({
          method: ErrorRedirectMethod.PUSH,
          errorCode: ErrorCodeType.FORBIDDEN,
          title: '사용이 정지된 계정입니다',
          message: '계정 정지를 해제하시려면 고객센터로 문의바랍니다.',
          description: bannedReason ?? `${ErrorCodeType.FORBIDDEN}`,
          buttonType: ErrorButtonType.CONTACT_CS,
        })
        break
      default:
        break
    }
  }, [user, handleErrorFullScreen, enabled])

  /**
   * 만약에 authRequired 인 페이지라면 로그인 요청
   */
  // FIXME: 계속 로그인을 시도하는 부분이 있어서 수정이 필요P
  useEffect(() => {
    if (!enabled) return
    const executeEffect = async (): Promise<void> => {
      if (authRequired && !loggedIn) {
        try {
          if (
            appBridge.renewAccessToken.isSupported() ||
            appBridge.renewTAuthToken.isSupported()
          ) {
            const appendedHeader: AdditionalHeaderForAuth = {}
            if (appBridge.renewAccessToken.isSupported()) {
              const newKToken = await appBridge.renewAccessToken()
              appendedHeader['X-KAKAOT-ACCESS-TOKEN'] = newKToken
              setCookie('X-KAKAOT-ACCESS-TOKEN', newKToken)
            }
            if (appBridge.renewTAuthToken.isSupported()) {
              const newTToken = await appBridge.renewTAuthToken()
              appendedHeader['X-TAUTH-ACCESS-TOKEN'] = newTToken
              setCookie('X-TAUTH-ACCESS-TOKEN', newTToken)
            }
            // 다시 재시도
            const user = await getUser({
              headers: appendedHeader,
            })
            if (!user) throw new Error('not logged in')
            dispatch(setUser(user))
          } else {
            throw new Error('neither renewToken allowed')
          }
        } catch (e) {
          console.error(e)
          const user = await getUser().catch(() => undefined)
          if (!user) handleLogin()
        }
      }
    }
    executeEffect()
  }, [authRequired, loggedIn, enabled, appBridge])
}

export default useAuthenticationGuard
