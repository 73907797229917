import React, { ReactElement, useState } from 'react'
import { ApolloProvider } from '@/apollo'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import { AppPropsWithParams, RouteInterceptor } from '@/router'
import useNotSupportGuard from '@/hooks/useNotSupportGuard'
import { ErrorBoundary } from '@/plugins/components/sentry'
import { ToastProvider } from '@kakaomobility/tui-react-maas/dist/maas'
import KakaoMobilityLoader from '@/components/KakaoMobilityLoader'
import AppHead from '@/components/AppHead'
import { wrapper } from '@/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import GlobalStyle from '@/styles/global'
import UserInitializer from '@/components/UserInitializer'
import { AppBridgeProvider } from '@kakaomobility/tui-react-maas'
import appBridge from '@kakaomobility/app-bridge'
import { ModalProvider } from '@/plugins/modal'
import Debugger from '@/components/debugger/Debugger'

const TOAST_Z_INDEX = 2147483648

export default function App(appProps: AppPropsWithParams): ReactElement {
  const { Component, ...restProps } = appProps
  const { store, props } = wrapper.useWrappedStore(restProps)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
          },
        },
      })
  )

  useNotSupportGuard()

  return (
    <>
      <AppHead {...(appProps?.pageProps?.meta ?? {})} />
      <ErrorBoundary>
        <ApolloProvider appProps={appProps}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={appProps?.pageProps?.dehydratedState}>
              <AppBridgeProvider appBridge={appBridge}>
                <ToastProvider zIndex={TOAST_Z_INDEX}>
                  <Provider store={store}>
                    <ModalProvider stackable>
                      <Debugger appProps={appProps} />
                      <GlobalStyle />
                      <PersistGate
                        persistor={(store as any).__persistor}
                        loading={null}
                      />
                      <KakaoMobilityLoader />
                      <RouteInterceptor
                        Component={Component}
                        {...props.pageProps}
                      />
                      <UserInitializer />
                    </ModalProvider>
                  </Provider>
                </ToastProvider>
              </AppBridgeProvider>
            </Hydrate>
          </QueryClientProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  )
}
