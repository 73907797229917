import React, { ReactElement } from 'react'
import Head from 'next/head'
import { MetaInfo } from '@/helpers/ssr/withSSR'

interface AppHeadProps extends MetaInfo {}

export default function AppHead({
  title,
  ogTitle,
  description,
  ogDescription,
  imageUrl,
  noIndex,
}: AppHeadProps): ReactElement {
  return (
    <Head>
      <title>{title ?? process.env.NEXT_PUBLIC_TITLE}</title>
      {noIndex && <meta name='robots' content='noindex' />}
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta name='theme-color' content='#000000' />
      <meta
        name='description'
        content={description ?? process.env.NEXT_PUBLIC_DESCRIPTION}
      />
      <meta
        name='keywords'
        content='버스대절, 관광버스대절, 전세버스, 미니버스대절,버스대여,버스대절비용,버스견적,셔틀버스,28인승리무진,45인승버스,버스렌트,중형버스렌트'
      />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='white' />
      <meta
        name='apple-mobile-web-app-title'
        content={title ?? process.env.NEXT_PUBLIC_TITLE}
      />
      <meta charSet='utf-8' />
      <link rel='shortcut icon' href='/favicon.ico' />

      <link rel='icon' sizes='96x96' href='/ic_web_96_shuttle_new.png' />
      <link
        rel='apple-touch-icon'
        sizes='120x120'
        href='/ic_web_120_shuttle_new.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='152x152'
        href='/ic_web_152_shuttle_new.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/ic_web_180_shuttle_new.png'
      />
      <link rel='icon' sizes='192x192' href='/ic_web_192_shuttle_new.png' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />

      <meta httpEquiv='Pragma' content='no-cache' />
      <meta httpEquiv='Cache-Control' content='max-age=0' />
      <meta httpEquiv='Cache-Control' content='no-cache' />
      <meta
        httpEquiv='Cache-Control'
        content='no-cache, no-store, must-revalidate'
      />
      <meta httpEquiv='Expires' content='Sat, 06 Sep 1986 13:47:00 GMT' />
      <meta httpEquiv='Expires' content='-1' />
      <meta
        name='viewport'
        content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=no,viewport-fit=cover'
      />
      <meta
        name='format-detection'
        content='telephone=no,address=no,email=no'
      />
      <meta httpEquiv='x-rim-auto-match' content='none' />

      <meta property='og:type' content='website' />
      <meta
        property='og:site_name'
        content={title ?? process.env.NEXT_PUBLIC_TITLE}
      />
      <meta property='og:locale' content='ko_KR' />
      <meta property='og:locale:alternate' content='en_US' />
      <meta
        property='og:title'
        content={ogTitle ?? process.env.NEXT_PUBLIC_TITLE}
      />
      <meta
        property='og:description'
        content={ogDescription ?? process.env.NEXT_PUBLIC_DESCRIPTION}
      />
      <meta
        property='og:image'
        content={
          imageUrl ?? `${process.env.NEXT_PUBLIC_CDN_URI}/images/og_image.png`
        }
      />
    </Head>
  )
}
