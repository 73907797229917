/**
 * @See https://kakaomobility.agit.in/g/300016227/wall/318998268#comment_panel_319153090 수정내용
 * @See https://kakaomobility.agit.in/g/300019201/wall/319403085#comment_panel_320366713 배포일
 * - KakaoTApp 의 경우, UserAgent 뒷 부분에 "KakaoTApp v#.#.#" 으로 명시 요청 (2019-02-14)
 * - iOS, 3.6.3
 * - Android, 3.6.4
 * @See https://kakaomobility.agit.in/g/300015681/wall/321776892#comment_panel_322738519 호출 규칙
 * - 인입 경로가 다양해짐에 따라, `^https?://.*?\.kakao(mobility)?\.com.*$` 대상으로 인증 정보 포함
 */
export const KAKAO_T_APP = /KakaoTApp v(\d+\.\d+\.\d+)/i
export const IOS_T_APP_UPDATE_VERSION = '>= 3.6.3'
export const ANDROID_T_UPDATE_VERSION = '>= 3.6.4'

export const KAKAOTALK_APP_WITHOUT_VERSION = /KAKAOTALK(?!(-scrap))/i

export const KAKAOTALK_APP = /KAKAOTALK( ?\d+\.\d+\.\d+)?/i
