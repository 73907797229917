export enum RoutePaths {
  OAUTH_REDIRECT_PATH = '/oauth',
  OAUTH_RENEW_TOKEN_PATH = '/oauth/renew',
  LOGIN_PATH = '/login',
  LOGOUT_PATH = '/logout',
  LOG_PATH = '/log',
  HEALTH_PATH = '/health',
  SET_TOKEN_PATH = '/setToken',
  BOOKING_PATH = '/booking',
  USER_ME = '/user/me',
  SHORTEN_URL = '/shorten-url',
  DEV_PATH = '/dev',
}

export enum ProxyPath {
  WEBVIEW_GATEWAY = '/api/proxy/webview-gateway',
}

export enum SessionProperty {
  KAKAO_AUTH_TOKEN_EXPIRED_AT = 'ka-oa-token-expired-at',
  KAKAO_AUTH_TOKEN = 'ka-oa-token',
  KAKAO_USER_ID = 'ka-uid',
  KAKAO_ACCOUNT_ID = 'ka-aid',
  KAKAO_AUTH_REFRESH_TOKEN = 'ka-oa-refresh-token',
  KAKAO_AUTH_REFRESH_TOKEN_EXPIRED_AT = 'ka-oa-refresh-token-expired-at',
  TAUTH_ACCESS_TOKEN = 'x-tauth-access-token',
  TAUTH_REFRESH_TOKEN = 'x-tauth-refresh-token',
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
  PUT = 'put',
}

export enum Browser {
  IE = 'ie',
  CHROME = 'chrome',
}

export enum MobileOS {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum Cookie {
  DEVICE_INFO = 'DID',
  SESSION_ID = 'BKID',
  FAKE_MOBILE_TOKEN = 'FAKE-MOBILE_TOKEN',
  ACCESS_TOKEN = 'X-KAKAOT-ACCESS-TOKEN',
  TAUTH_ACCESS_TOKEN = 'X-TAUTH-ACCESS-TOKEN',
  HAS_TOKEN = 'HTID',
}

export enum Header {
  KAKAOT_TOKEN = 'x-kakaot-access-token',
  TAUTH_TOKEN = 'x-tauth-access-token',
}

export const Constants = {
  Browser,
  RoutePaths,
  SessionProperty,
  HttpMethod,
  MobileOS,
  Cookie,
  Header,
}

export default Constants
