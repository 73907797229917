import { RoutePaths } from '@/shared'
import { DebugButton, useToast } from '@kakaomobility/tui-react-maas/dist/maas'
import React from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import instance from '@/services/instance'

export default function ExpireTTokenButton(): ReactElement {
  const toast = useToast()
  return (
    <DebugButton
      onClick={async () => {
        await instance.get(`${RoutePaths.DEV_PATH}/token/expire/tauth`)
        toast.show('만료되었습니다.')
      }}
    >
      T 토큰 만료
    </DebugButton>
  )
}
