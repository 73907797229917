import { combineReducers } from '@reduxjs/toolkit'
import { userSlice } from '@/store/modules/user'
import { orderSlice } from '@/store/modules/order'
import { shuttleTicketSlice } from '@/store/modules/shuttleTicket'
import { deviceSlice } from '@/store/modules/device'
import { tiaraSlice } from '@/store/modules/tiara'
import { loaderSlice } from '@/store/modules/loader'
import { charterProductSlice } from '@/store/modules/charterProduct'
import { charterTicketSlice } from '@/store/modules/charterTicket'

const reducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [orderSlice.name]: orderSlice.reducer,
  [shuttleTicketSlice.name]: shuttleTicketSlice.reducer,
  [deviceSlice.name]: deviceSlice.reducer,
  [tiaraSlice.name]: tiaraSlice.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [charterProductSlice.name]: charterProductSlice.reducer,
  [charterTicketSlice.name]: charterTicketSlice.reducer,
})

export type ReducerType = ReturnType<typeof reducer>

export default reducer
