import { useCallback } from 'react'
import { ErrorButtonType, ErrorCodeType, ErrorRedirectMethod } from '@/enums'
import { errorMessage, ExpectedError } from '@/helpers/errorMessageHelper'
import { ErrorLevel } from '@/containers/errors/ErrorFullScreenMessage'
import { useRouter, PageError } from '@/router'
import DialogAlert from '@/components/dialogs/DialogAlert'
import { useModal } from '@kakaomobility/tui-react-maas/dist/maas'

export interface handleErrorFullScreenProps {
  method: ErrorRedirectMethod
  message: ExpectedError
  buttonType: ErrorButtonType
  callback: () => void
  buttonLabel: string | number
  title: string | number
  description: string | number
  errorCode: ErrorCodeType
  level: ErrorLevel
  url: string
}

export interface UseErrorRenderHandler {
  handleErrorFullScreen: ({
    method,
    level,
    title,
    message,
    description,
    errorCode,
    buttonType,
    callback,
    buttonLabel,
  }: Partial<handleErrorFullScreenProps>) => null
  handleErrorAlert: (
    message?: ExpectedError | Error,
    callback?: () => void
  ) => Promise<void>
}

function useErrorRenderHandler(): UseErrorRenderHandler {
  const modal = useModal()
  const router = useRouter()

  const handleErrorAlert = useCallback(
    async (
      message?: ExpectedError | Error,
      callback?: () => void
    ): Promise<void> => {
      await modal.push('ErrorDialog', DialogAlert, {
        message: errorMessage(message) ?? '오류가 발생했습니다.',
        callback,
      })
      console.error(message)
    },
    []
  )

  const handleErrorFullScreen = useCallback(
    ({
      method = ErrorRedirectMethod.REPLACE,
      level,
      title,
      message,
      description,
      errorCode,
      buttonType,
      buttonLabel,
      url,
    }: Partial<handleErrorFullScreenProps>): null => {
      const redirectAction =
        method === ErrorRedirectMethod.REPLACE ? router.replace : router.push
      redirectAction(
        PageError.toString({
          query: {
            level,
            title: `${title}`,
            description,
            errorCode,
            buttonType,
            buttonLabel: `${buttonLabel}`,
            url,
            message: errorMessage(message),
          },
        })
      )
      console.error(message)
      return null
    },
    [router.push, router.replace]
  )

  return {
    handleErrorFullScreen,
    handleErrorAlert,
  }
}

export default useErrorRenderHandler
