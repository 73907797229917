import { useMemo } from 'react'
import { ApolloProxy, APOLLO_HYDRATION_STATE_PROP_NAME } from '@/apollo'

export const useApollo = (pageProps: any): any => {
  const state = useMemo(
    () => pageProps[APOLLO_HYDRATION_STATE_PROP_NAME],
    [pageProps]
  )
  const client = useMemo(() => new ApolloProxy().getClient(state), [state])
  return client
}
