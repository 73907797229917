/* basic setting for routes */
export * from './Route'
export { default as Route } from './Route'

/* module routes */
export * from './shuttle'
export * from './common'
export * from './bookings'
export * from './orders'
export * from './charterbus'
export * from './all-charters'
export * from './festival'
