import { Route } from '@/router/routes/index'
import { BookingVerticalPath } from '@/enums'

export enum BookingType {
  DEFAULT = 'default', // 상세페이지에서 예약하는 경우
  CHECKOUT = 'checkout', // 개설 flow에서 예약하는 경우
}

export interface PageBookingListQueries {
  vertical?: string
}
export const PageBookingList = new Route<never, PageBookingListQueries>(
  '/bookings'
)

export interface PageBookingNewParams {
  vertical: BookingVerticalPath
  verticalItemId: string
}
export interface PageBookingNewQueries {
  view?: string
  bookingType?: BookingType
  prevPage?: string
  date?: string
  stationId?: string
  amount?: string
}
export const PageBookingNew = new Route<
  PageBookingNewParams,
  PageBookingNewQueries
>('/bookings/new/:vertical/:verticalItemId')

export interface PageBookingItemParams {
  bookingId: string
}
export interface PageBookingItemQueries {
  vertical?: BookingVerticalPath
  dialogType?: string
}
export const PageBookingItem = new Route<
  PageBookingItemParams,
  PageBookingItemQueries
>('/bookings/:bookingId')

export interface PageBookingTicketParams {
  bookingId?: string
}
export interface PageBookingTicketQueries {
  vertical?: string
}
export const PageBookingTicket = new Route<
  PageBookingTicketParams,
  PageBookingTicketQueries
>('/bookings/:bookingId/views/ticket')
