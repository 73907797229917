import { KAKAO_T_APP, KAKAOTALK_APP } from '../regexp/kakao'

export interface DeviceInfo {
  os: string | null
  osVersion: string | null
  browser: string | null
  browserVersion: string | null
  kakaoTApp: boolean
  kakaoTAppVersion: string | null
  kakaoTalkApp: boolean
  kakaoTalkAppVersion: string | null
  uaString: string | null
}

const isNodeJS = (() =>
  Object.prototype.toString.call(global?.process) === '[object process]')()

export const encodeDeviceInfo = (deviceInfo: DeviceInfo): string => {
  const encoder = isNodeJS
    ? (value: string) => Buffer.from(value).toString('base64')
    : btoa
  return encoder(JSON.stringify(deviceInfo))
}

export const decodeDeviceInfo = (
  encodedDeviceInfo?: string
): DeviceInfo | undefined => {
  if (!encodedDeviceInfo) return
  try {
    const decoder = isNodeJS
      ? (value: string) => Buffer.from(value, 'base64').toString()
      : atob
    return JSON.parse(decoder(decodeURIComponent(encodedDeviceInfo!)))
  } catch (e) {}
}

export const parseUserAgent = (
  parser: any,
  ua?: string
): DeviceInfo | undefined => {
  if (!ua || !parser) return
  function parse() {
    const { os, browser } = parser(ua)
    return {
      os: os?.name?.toLowerCase() || null,
      osVersion: os?.version || null,
      browser: browser?.name?.toLowerCase() || null,
      browserVersion: browser?.version || null,
    }
  }
  const parsed = parse()
  let [browser, browserVersion] = [parsed.browser, parsed.browserVersion]
  let [os, osVersion] = [parsed.os, parsed.osVersion]
  const kakaoTApp = KAKAO_T_APP.test(ua)
  let kakaoTAppVersion = null
  if (kakaoTApp) {
    const [, version] = KAKAO_T_APP.exec(ua) ?? [, null]
    kakaoTAppVersion = version!
  }
  const kakaoTalkApp = KAKAOTALK_APP.test(ua)
  let kakaoTalkAppVersion = null
  if (kakaoTalkApp) {
    const [, version] = KAKAOTALK_APP.exec(ua) ?? [, null]
    kakaoTalkAppVersion = version!
  }

  return {
    os,
    osVersion,
    kakaoTApp,
    kakaoTAppVersion,
    kakaoTalkApp,
    kakaoTalkAppVersion,
    browser,
    browserVersion,
    uaString: ua ?? null,
  }
}
