import { withRouter as withNextRouter } from 'next/router'
import { ExtendedNextRouter } from '@/router'
import { NextComponentType, NextPageContext } from 'next'

export interface WithRouterProps {
  router: ExtendedNextRouter
}

export function withRouter(
  component: NextComponentType<NextPageContext, any, WithRouterProps>
): NextComponentType {
  return withNextRouter(component)
}
