import { stringify, parse } from 'flatted'
import { isServerSide } from '@kakaomobility/tui-react-maas'

export const CUSTOM_LOGGER_PROPS_KEY = 'CUSTOM_LOGGER_PROPS_KEY'
export const CUSTOM_APP_LOGGER_PROPS_KEY = 'CUSTOM_APP_LOGGER_PROPS_KEY'
export type CustomLoggerInstanceType = InstanceType<typeof CustomLogger>
type LoggerType = 'client' | 'server'
interface LoggerEntity {
  type: LoggerType
  msg: any | any[]
}

/**
 * @description
 * server에서 log를 찍어보고 client에서 확인하고 싶을때 사용하는 util +
 * 로거를 Vconsole에서 찍어보고 싶을때 씀.
 */
class CustomLogger {
  private isHydrated = false
  private loggingStack: LoggerEntity[] = []

  private reset(): void {
    this.loggingStack = []
  }

  setLoggingStack(state?: LoggerEntity[]): this {
    this.loggingStack = [...(state ?? []), ...this.loggingStack]
    return this
  }

  getLoggingProps(): LoggerEntity[] {
    const prev = [...this.loggingStack]
    this.reset()
    return prev
  }

  log(...msgs: any): void {
    if (this.isHydrated) {
      this.customLog({
        type: isServerSide() ? 'server' : 'client',
        msg: stringify(msgs),
      })
    } else {
      this.loggingStack.push({
        type: isServerSide() ? 'server' : 'client',
        msg: stringify(msgs),
      })
    }
  }

  private customLog(loggerEntity: LoggerEntity): void {
    if (process.env.NEXT_PUBLIC_BUILD_ENV === 'production') return /* guard */
    if (loggerEntity.type === 'server') {
      console.log(
        `%cThis msg is printed at server:`,
        'color: white; background: violet'
      )
    }
    console.log(...parse(loggerEntity.msg))
  }

  printAll(): void {
    this.loggingStack.forEach((msg) => {
      this.customLog(msg)
    })
    this.reset()
    this.isHydrated = true
  }
}

export const customLogger = new CustomLogger()
