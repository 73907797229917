import { Route } from '@/router/routes/index'
/* enums */
import { ErrorButtonType, ErrorCodeType } from '@/enums'
/* components */
import { ErrorLevel } from '@/containers/errors/ErrorFullScreenMessage'
import { RentType, RouteType, ShuttleGrade } from '@/__generated__/globalTypes'

export interface PageErrorQueries {
  errorCode?: ErrorCodeType
  message?: string
  title?: string
  description?: string | number
  buttonType?: ErrorButtonType
  level?: ErrorLevel
  buttonLabel?: string
  url?: string
}
export const PageError = new Route<never, PageErrorQueries>('/error')

export interface PageUiSandboxQueries {
  module: string
}
export const PageUiSandbox = new Route<never, PageUiSandboxQueries>('/sandbox')

export interface PageGateQueries {
  departure_adr?: string
  departure_lat?: string
  departure_lng?: string
  departure_title?: string
  arrival_adr?: string
  arrival_lat?: string
  arrival_lng?: string
  arrival_title?: string
  rent_type?: RentType
  route_type?: RouteType
  seater?: string
  shuttle_grade?: ShuttleGrade
  origin_dt?: string // ex> ?origin_dt=2022-10-25T11:05:00
  dest_dt?: string // ex> ?dest_dt=2022-10-25T11:05:00
  route_to?: string
  share?: string
}
export const PageGate = new Route<never, PageGateQueries>('/gate')

interface PageUnpaidItemParams {
  callId: string | number
}
interface PageUnpaidItemQueries {
  dialogType?: string
  payAmount?: string
}
export const PageUnpaidItem = new Route<
  PageUnpaidItemParams,
  PageUnpaidItemQueries
>('/unpaids/:callId')

export const PageNotSupport = new Route<never, never>('/not-support')
