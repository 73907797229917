import { HYDRATE } from 'next-redux-wrapper'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ShuttleUserInfo } from '@/shared/interfaces'
import { TUserStatus } from '@/__generated__/globalTypes'

export type UserState = ShuttleUserInfo

const initialState: { hydratedFlag: boolean; userInfo: UserState } = {
  hydratedFlag: false,
  userInfo: {
    id: -1,
    uid: -1,
    name: '',
    phone: '',
    email: '',
    bannedReason: TUserStatus.NORMAL,
    state: TUserStatus.UNAUTHORIZED,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      if (state.userInfo.id === action.payload?.id && !action.payload)
        return /* guard */
      state.userInfo = action.payload
    },
    resetUser(state) {
      state.userInfo = initialState.userInfo
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: { type: any; payload: any }) => {
      if (state.hydratedFlag) return /* guard */
      return {
        ...state,
        ...action.payload.user,
        hydratedFlag: true,
      }
    })
  },
})

export const { setUser, resetUser } = userSlice.actions
