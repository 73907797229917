import { isProductionNode } from '@/shared/utils/envHelper'
import {
  getCookie,
  setCookie as setCookieMaaS,
  removeCookie,
  CookieAttributes,
} from '@kakaomobility/tui-react-maas/dist/common/helper/cookie'

// TODO: 타입 통일 필요
export const setCookie = (
  name: string,
  value: string,
  options: CookieAttributes = {}
): void => {
  const production = isProductionNode()
  setCookieMaaS(name, value, {
    ...options,
    sameSite: production ? 'lax' : 'none',
    secure: production,
  })
}

export { getCookie, removeCookie }
