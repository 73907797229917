import { SeatInventoryType, ShuttleDivision } from '@/__generated__/globalTypes'
import { BookingVertical, BookingVerticalPath } from '@/enums'

export function seatInventoryToDivision(
  seatInventoryType: SeatInventoryType
): ShuttleDivision {
  switch (seatInventoryType) {
    case SeatInventoryType.ASSIGNED_SEAT:
      return ShuttleDivision.DESIGNATED
    case SeatInventoryType.FREE_SEAT:
    default:
      return ShuttleDivision.FREE
  }
}

export function toBookingVertical(
  verticalPath?: BookingVerticalPath | string
): BookingVertical {
  // verticalPath 와 현재는 상관이 없음
  return BookingVertical.SHUTTLE
}
