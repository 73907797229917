import { ItemType } from '@/__generated__/globalTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResultPayInfoPayload } from '@/components/payments/PaymentType'

export interface OrderState {
  orderId: string
  originalPrice: string | number | null
  verticalTotalPrice: string | number | null
  paymentWebMethod?: ResultPayInfoPayload['method'] | null
  paymentWebPayInfo?: ResultPayInfoPayload['pay_info'] | null
  tPointInfo?: ResultPayInfoPayload['tpoint'] | null
  shuttleType?: ItemType
}

const initialState: OrderState = {
  orderId: '',
  originalPrice: null,
  verticalTotalPrice: null,
  paymentWebMethod: null,
  shuttleType: undefined,
  paymentWebPayInfo: null,
  tPointInfo: null,
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setVerticalTotalPrice(
      state,
      action: PayloadAction<string | number | null>
    ) {
      state.verticalTotalPrice = action.payload
    },
    resetVerticalTotalPrice(state) {
      state.verticalTotalPrice = initialState.verticalTotalPrice
    },
    setOrderId(state, action: PayloadAction<string>) {
      state.orderId = action.payload
    },
    setOriginalPrice(state, action: PayloadAction<string | number | null>) {
      state.originalPrice = action.payload
    },
    resetOriginalPrice(state) {
      state.originalPrice = initialState.originalPrice
    },
    setPaymentWebPayInfo(
      state,
      action: PayloadAction<ResultPayInfoPayload['pay_info'] | undefined>
    ) {
      state.paymentWebPayInfo = action.payload
    },
    setTPointInfo(
      state,
      action: PayloadAction<ResultPayInfoPayload['tpoint'] | undefined>
    ) {
      state.tPointInfo = action.payload
    },
    setPaymentWebMethod(
      state,
      action: PayloadAction<ResultPayInfoPayload['method'] | undefined>
    ) {
      state.paymentWebMethod = action.payload
    },
    resetPaymentWebMethod(state) {
      state.paymentWebMethod = initialState.paymentWebMethod
    },
    resetPaymentWebPayInfo(state) {
      state.paymentWebPayInfo = initialState.paymentWebPayInfo
    },
    resetTPointInfo(state) {
      state.tPointInfo = initialState.tPointInfo
    },
    setShuttleType(state, action: PayloadAction<ItemType | undefined>) {
      state.shuttleType = action.payload
    },
    resetOrder(state) {
      state.orderId = initialState.orderId
      state.verticalTotalPrice = initialState.verticalTotalPrice
      state.originalPrice = initialState.originalPrice
      state.paymentWebMethod = initialState.paymentWebMethod
      state.shuttleType = initialState.shuttleType
    },
  },
})

/* actions */
export const {
  setPaymentWebPayInfo,
  resetPaymentWebPayInfo,
  setTPointInfo,
  resetTPointInfo,
  setVerticalTotalPrice,
  setOrderId,
  setOriginalPrice,
  resetOriginalPrice,
  setPaymentWebMethod,
  resetPaymentWebMethod,
  resetVerticalTotalPrice,
  setShuttleType,
  resetOrder,
} = orderSlice.actions
