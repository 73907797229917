import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { isServerSide } from '@kakaomobility/tui-react-maas'
import reducer from '@/store/reducer'
import { persistStore, persistReducer } from 'redux-persist'
import { orderSlice } from '@/store/modules/order'
import storageSession from 'redux-persist/lib/storage/session'
import assert from 'assert'
import { charterProductSlice } from '@/store/modules/charterProduct'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeConfiguredStore = (_reducer: typeof reducer) =>
  configureStore({
    reducer: _reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', '__NEXT_REDUX_WRAPPER_HYDRATE__'],
        },
      }),
  })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeStore = () => {
  if (isServerSide()) {
    return makeConfiguredStore(reducer)
  } else {
    assert(
      process.env.NEXT_PUBLIC_REDUX_PERSIST_KEY,
      'NEXT_REDUX_PERSIST_KEY should be defined.'
    )
    const persistConfig = {
      key: process.env.NEXT_PUBLIC_REDUX_PERSIST_KEY ?? '',
      whitelist: [charterProductSlice.name, orderSlice.name],
      storage: storageSession,
    }
    const persistedReducer = persistReducer(persistConfig, reducer)
    // eslint-disable-next-line
    // @ts-ignore
    const store = makeConfiguredStore(persistedReducer)
    // eslint-disable-next-line
    // @ts-ignore
    store.__persistor = persistStore(store)
    return store
  }
}

export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']
export type AppState = ReturnType<AppStore['getState']>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const wrapper = createWrapper<AppStore>(makeStore)
