import { css, SerializedStyles } from '@emotion/react'

export enum ImageType {
  TITLE = 'title',
  INFO_BUS_NEW = 'info_bus_new',
  INFO_GUIDE = 'info_guide',
  HANDLE = 'handle',
  NOTINUSE = 'notinuse',
  MAIN_BANNER = 'main_banner_img',
  KAKAOMAP_ICON = 'link_kakaomap',
  COVID19_PREVENTION = 'covid19_prevention',
  INFO_TAXI = 'info_taxi',
  IC_24_ARROW_DATE_LEFT_NORMAL = 'ic_24_arrow_date_left_nomal',
  IC_24_ARROW_DATE_RIGHT_NORMAL = 'ic_24_arrow_date_right_nomal',
  IC_24_ARROW_DATE_LEFT_DISABLE = 'ic_24_arrow_date_left_disable',
  IC_24_ARROW_DATE_RIGHT_DISABLE = 'ic_24_arrow_date_right_disable',
  IMG_BUS_01 = 'img_bus_01',
  IMG_BUS_02 = 'img_bus_02',
  IMG_BUS_03 = 'img_bus_03',
  IMG_BUS_04 = 'img_bus_04',
  IMG_BUS_05 = 'img_bus_05',
  AD_TOURVIS = 'ad_tourvis',
  IC_SHUTTLE_12 = 'ic_shuttle_12',
  IC_SHUTTLE_18 = 'ic_shuttle_18',
  IC_SHUTTLE_28_44 = 'ic_shuttle_28,44',
  IMG_48_RESULT_NONE = 'img_48_result_none',
  IMG_CITY_TOUR_POPUP = 'img_city_tour_popup',
  IMG_CITY_TOUR_BANNER = 'img_city_tour_banner',
}

export const STYLE_POINTER = css`
  outline: none;
`

export const STYLE_KEEP_WORD = css`
  word-break: keep-all;
  word-wrap: break-word;
`

export const STYLE_CHILDREN_CENTER = css`
  white-space: nowrap;
  text-align: center;
  &::before {
    content: '';
    overflow: hidden;
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }
`

export const STYLE_MOMENTUM_SCROLL = css`
  overflow: auto;
  -webkit-overflow-scrolling: auto;
`

export const STYLE_CLEAR_FIX = css`
  &::after {
    content: '';
    overflow: hidden;
    display: table;
    clear: both;
    width: 100%;
    height: 0;
    font-size: 0;
    line-height: 0;
  }
`

export const STYLE_ABSOLUTE_AUTO_EXPAND = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const STYLE_MULTI_BACKGROUND = (
  name: ImageType
): SerializedStyles => css`
  background-image: url(${`${process.env.NEXT_PUBLIC_CDN_URI}/images/${name}.png`});
  background-image: url(${`${process.env.NEXT_PUBLIC_CDN_URI}/images/${name}.svg`}),
    none;
`

export const STYLE_SAFE_BACKGROUND_IMAGE = (
  type: ImageType,
  width: number,
  height = width,
  size: 'contain' | 'cover' | '100%' = 'contain'
): SerializedStyles => css`
  width: ${width}px;
  height: ${height}px;
  background-size: ${size};
  background-repeat: no-repeat;
  ${STYLE_MULTI_BACKGROUND(type)}
`

export const STYLE_MULTILINE_CLAMP = (
  line: number,
  fontLineHeight: number = 22
): SerializedStyles => {
  return css`
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: ${line};
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: ${fontLineHeight * line}px;
    word-break: break-all;
  `
}
