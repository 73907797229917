import React, { ReactElement } from 'react'
import { css, Global } from '@emotion/react'
import { useAppSelector } from '@/store'
import { isKakaoTSelector } from '@/store/selectors/device'
import { STYLE_MOMENTUM_SCROLL } from '@/styles/presets'
import {
  FONT_16,
  FONT_FAMILY_MONOSPACE,
  StyleFontSize,
  StyleFontWeight,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import { getBaseStyle, getWebViewBaseStyle } from '@kakaomobility/tui-core'

export default function GlobalStyle(): ReactElement {
  const isKakaoT = useAppSelector(isKakaoTSelector)
  return (
    <Global
      styles={css`
        ${getBaseStyle('__next')};
        ${getWebViewBaseStyle('__next')};
        html {
          /* 단위 rem 을 10px 기준으로 하여 계산 편하게 하기 위함 */
          font-size: ${StyleFontSize._ROOT};
          font-weight: ${StyleFontWeight.REGULAR};
          line-height: 1.4;
          ${isKakaoT &&
          css`
            user-select: none;
          `}
        }
        body {
          margin: 0;
          background-color: ${TColors.BG};
          ${FONT_16};
          ${STYLE_MOMENTUM_SCROLL};
        }
        code {
          font-size: ${StyleFontSize._ROOT};
          ${FONT_FAMILY_MONOSPACE};
        }
        html,
        body {
          height: 100%; // IE 11 대응
          ${isKakaoT
            ? css`
                height: 100vh;
              `
            : ''};
        }
        a {
          color: ${TColors.NEUTRAL1};
          text-decoration: none;
        }
        button {
          margin: 0;
          padding: 0;
          border: 0 none;
          background-color: transparent;
          cursor: pointer;
          color: ${TColors.NEUTRAL1};
        }
        input {
          caret-color: ${TColors.PRIMARY1};
        }
        input::-ms-clear {
          display: none;
        }
        menu {
          list-style: none;
        }
        button,
        select,
        textarea,
        input,
        a,
        area {
          outline: none;
          -webkit-tap-highlight-color: ${TColors.NEUTRAL6};
        }
      `}
    />
  )
}
