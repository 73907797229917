/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BookingStatus {
  BOOKED = "BOOKED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  WAIT = "WAIT",
}

export enum DestinationType {
  ARRIVAL = "ARRIVAL",
  DEPARTURE = "DEPARTURE",
}

export enum DispatchStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  DISPATCHED = "DISPATCHED",
  FAILED = "FAILED",
  WAIT = "WAIT",
}

export enum DisplayType {
  BANNER = "BANNER",
  LIST = "LIST",
}

export enum DrivingScheduleStatus {
  ENDED = "ENDED",
  ON_SALE = "ON_SALE",
  PREPARING = "PREPARING",
  SUSPENDED = "SUSPENDED",
}

export enum GoodsSeatType {
  FREE_SEAT = "FREE_SEAT",
  RESERVED_SEAT = "RESERVED_SEAT",
}

export enum ItemType {
  CHANNELING_SHUTTLE_ITEM = "CHANNELING_SHUTTLE_ITEM",
  OPEN_SHUTTLE_ITEM = "OPEN_SHUTTLE_ITEM",
  RENT_BUS_ITEM = "RENT_BUS_ITEM",
  T_SHUTTLE_ITEM = "T_SHUTTLE_ITEM",
}

export enum PaymentMethod {
  CARD = "CARD",
  FAMILY_SHARE = "FAMILY_SHARE",
  TPOINT = "TPOINT",
}

export enum PaymentStatus {
  CANCEL = "CANCEL",
  PAY = "PAY",
  REPAY = "REPAY",
  WAIT = "WAIT",
}

export enum PaymentType {
  AUTO_SHUTTLE_FARE = "AUTO_SHUTTLE_FARE",
  CHARTERBUS_FARE = "CHARTERBUS_FARE",
  OPEN_SHUTTLE_FARE = "OPEN_SHUTTLE_FARE",
  T_SHUTTLE_FARE = "T_SHUTTLE_FARE",
}

export enum PromotionStatus {
  CLOSE = "CLOSE",
  IN_PROGRESS = "IN_PROGRESS",
  WAIT = "WAIT",
}

export enum PurchaseStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  ORDERED = "ORDERED",
}

export enum RentType {
  CHARTER = "CHARTER",
  CROWD = "CROWD",
}

export enum RouteType {
  ONE_WAY = "ONE_WAY",
  ROUND_TRIP = "ROUND_TRIP",
}

export enum SalesStatus {
  ENDED = "ENDED",
  ON_SALE = "ON_SALE",
  PREPARING = "PREPARING",
  SUSPENDED = "SUSPENDED",
}

export enum SeatInventoryType {
  ASSIGNED_SEAT = "ASSIGNED_SEAT",
  FREE_SEAT = "FREE_SEAT",
}

export enum SeatType {
  MINI_12 = "MINI_12",
  MINI_18 = "MINI_18",
  ORDINARY_44 = "ORDINARY_44",
  ORDINARY_45 = "ORDINARY_45",
  PREMIUM_28 = "PREMIUM_28",
  PREMIUM_31 = "PREMIUM_31",
}

export enum ShuttleDivision {
  DESIGNATED = "DESIGNATED",
  FREE = "FREE",
}

export enum ShuttleGrade {
  MINI = "MINI",
  ORDINARY = "ORDINARY",
  PREMIUM = "PREMIUM",
}

export enum ShuttleSpecExtraComponentType {
  BACK_SEAT = "BACK_SEAT",
  DRIVER_SEAT = "DRIVER_SEAT",
  EXIT_DOOR = "EXIT_DOOR",
}

export enum SlotStatus {
  ENDED = "ENDED",
  ON_SALE = "ON_SALE",
  SUSPENDED = "SUSPENDED",
}

export enum SpotType {
  FIXED = "FIXED",
  TEMPORARY = "TEMPORARY",
}

export enum StartPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum StationType {
  SHUTTLE_STOP = "SHUTTLE_STOP",
  SPOT = "SPOT",
}

export enum TUserStatus {
  BANNED = "BANNED",
  DEREGISTER = "DEREGISTER",
  FORBIDDEN = "FORBIDDEN",
  NORMAL = "NORMAL",
  NOT_FOUND = "NOT_FOUND",
  SLEEPING = "SLEEPING",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export enum TransportationType {
  BUS = "BUS",
}

export enum TravelScheduleItemType {
  ARRIVAL_STATION_LINK = "ARRIVAL_STATION_LINK",
  DEPARTURE_STATION_LINK = "DEPARTURE_STATION_LINK",
  PLACE_LINK = "PLACE_LINK",
  PLAIN = "PLAIN",
}

export enum Vertical {
  AUTOVEHICLE = "AUTOVEHICLE",
  RENTBUS = "RENTBUS",
  SHUTTLE = "SHUTTLE",
}

/**
 * input 형태는 각기 다를 것이기 때문에 input 타입은 common으로 옮기지 않는다
 */
export interface LocationInput {
  latitude: number;
  longitude: number;
}

export interface TemporalSpotInput {
  title: string;
  location: LocationInput;
  address: string;
}

export interface TemporalStationInput {
  title: string;
  location: LocationInput;
  address: string;
  departureDate?: string | null;
  departureTime?: string | null;
  index?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
