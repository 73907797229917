import {
  QueryShuttleCheckoutSchedule,
  QueryShuttleCheckoutSchedule_listStationDrivingSchedule,
} from '@/__generated__/QueryShuttleCheckoutSchedule'
import { ItemType, RentType } from '@/__generated__/globalTypes'
import { MutationShuttleCheckoutVariables } from '@/__generated__/MutationShuttleCheckout'
import {
  QueryDrivingSchedulePrice,
  QueryDrivingSchedulePrice_inquireDrivingSchedulePrice,
} from '@/__generated__/QueryDrivingSchedulePrice'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toSafeNumber } from '@/helpers/primitiveTool'
import {
  seatInventoryToDivision,
  toBookingVertical,
} from '@/helpers/bookings/bookingLabel'

interface ShuttleTicketDisplayRequiredState {
  seatNumbers: number[]
  schedules: QueryShuttleCheckoutSchedule_listStationDrivingSchedule[]
  maximum: number
  minimum: number
  quantity: number
}

interface ShuttleTicketDisplayOptionalState {
  title: string
  thumbnail: string
  rentType: RentType | null
}

export type ShuttleTicketState = ShuttleTicketDisplayRequiredState &
  Partial<ShuttleTicketDisplayOptionalState> &
  Partial<MutationShuttleCheckoutVariables> &
  Partial<QueryShuttleCheckoutSchedule_listStationDrivingSchedule> &
  Partial<QueryDrivingSchedulePrice_inquireDrivingSchedulePrice>

const initialState: ShuttleTicketState = {
  division: undefined,
  itemId: '',
  itemType: undefined,
  maximum: 1,
  minimum: 1,
  numbers: undefined,
  originId: 0,
  quantity: 1,
  routeType: undefined,
  scheduleId: '',
  schedules: [],
  seatNumbers: [],
  shuttleSpec: undefined,
  slotId: undefined,
  stationWorkScheduleId: '',
  originTicketPrice: undefined,
  totalPrice: undefined,
  thumbnail: undefined,
  title: undefined,
  vertical: '',
  rentType: undefined,
}

export const shuttleTicketSlice = createSlice({
  name: 'shuttleTicket',
  initialState,
  reducers: {
    setItemId(state, action: PayloadAction<string>) {
      state.itemId = `${toSafeNumber(action.payload, -1)}`
    },
    setVertical(state, action: PayloadAction<string | undefined>) {
      state.vertical = toBookingVertical(action.payload ?? '')
    },
    setQuantity(state, action: PayloadAction<string | number | undefined>) {
      state.quantity = isNaN(Number(action.payload))
        ? 1
        : Number(action.payload)
    },
    setByQuerySchedule(
      state,
      action: PayloadAction<QueryShuttleCheckoutSchedule>
    ) {
      if (
        !action.payload ||
        !action.payload.item ||
        !Array.isArray(action.payload.listStationDrivingSchedule)
      )
        return /* guard */
      const {
        title,
        id: itemId,
        type: itemType,
        thumbnail: thumbnailMap,
        rentType,
      } = action.payload.item ?? {}
      const { listStationDrivingSchedule: schedules } = action.payload ?? {}
      const thumbnail = thumbnailMap?.imageUrl ?? ''
      const maximum =
        Array.isArray(schedules) && schedules?.length > 1
          ? process.env.NEXT_PUBLIC_HEADCOUNT_MAX
          : schedules?.[0]?.availableSeatCount ?? 1
      const quantity =
        itemType === ItemType.RENT_BUS_ITEM ? maximum : state.quantity
      const minimum =
        itemType === ItemType.RENT_BUS_ITEM ? maximum : state.minimum
      state.schedules = schedules
      state.itemId = itemId
      state.itemType = itemType
      state.title = title
      state.rentType = rentType
      state.thumbnail = thumbnail
      state.maximum = Number(maximum)
      state.minimum = Number(minimum)
      state.quantity = Number(quantity)
    },
    setByQueryPrice(state, action: PayloadAction<QueryDrivingSchedulePrice>) {
      if (!action.payload?.inquireDrivingSchedulePrice) return /* guard */
      const { displayTotalPrice, originTicketPrice, totalPrice } =
        action.payload.inquireDrivingSchedulePrice
      if (
        state.displayTotalPrice !== undefined &&
        state.originTicketPrice !== undefined &&
        state.totalPrice !== undefined &&
        displayTotalPrice === state.displayTotalPrice &&
        originTicketPrice === state.originTicketPrice &&
        totalPrice === state.totalPrice
      )
        return /* guard */
      state.originTicketPrice = originTicketPrice
      state.totalPrice = totalPrice
      state.displayTotalPrice = displayTotalPrice
    },
    pickScheduleById(state, action: PayloadAction<string | undefined>) {
      const [picked] = state.schedules.filter(
        (schedule) => schedule.drivingScheduleId === action.payload
      )
      if (!picked) return /* guard */
      const {
        slotId,
        price,
        seatInventoryType,
        itemType,
        routeType,
        shuttleSpec,
        departureStationInfo,
      } = picked
      if (slotId === null || slotId === undefined) return /* guard */
      const division = seatInventoryToDivision(seatInventoryType)
      state.originId = Number(departureStationInfo?.stationWorkScheduleId)
      state.stationWorkScheduleId =
        departureStationInfo?.stationWorkScheduleId?.toString()
      state.seatNumbers = []
      state.slotId = slotId
      state.scheduleId = action.payload
      state.shuttleSpec = shuttleSpec
      state.price = price
      state.division = division
      state.itemType = itemType
      state.routeType = routeType
    },
    addSeatNumber(state, action: PayloadAction<string | number | null>) {
      state.seatNumbers = [
        ...new Set([...state.seatNumbers, Number(action.payload)]),
      ]
    },
    removeSeatNumber(state, action: PayloadAction<string | number | null>) {
      state.seatNumbers = state.seatNumbers.filter(
        (value) => value !== Number(action.payload)
      )
    },
    resetSeatNumber(state) {
      state.seatNumbers = initialState.seatNumbers
    },
    resetShuttleTicket(state) {
      Object.keys(state).forEach((key) => {
        // @ts-expect-error
        state[key] = initialState[key]
      })
    },
  },
})

/* actions */
export const {
  setItemId,
  setVertical,
  setQuantity,
  setByQuerySchedule,
  setByQueryPrice,
  pickScheduleById,
  addSeatNumber,
  removeSeatNumber,
  resetShuttleTicket,
  resetSeatNumber,
} = shuttleTicketSlice.actions
