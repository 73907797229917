import useAuthentication from '@/services/hooks/queries/accounts/useAuthentication'
import { useEffect } from 'react'
import {
  setUserIdentifier,
  getLogger,
} from '@kakaomobility/tui-react-maas/dist/common/helper/logger'
import appBridge from '@kakaomobility/app-bridge'
import { useAppSelector } from '@/store'
import { userInfoSelector } from '@/store/selectors/user'
import { getCookie } from '@kakaomobility/tui-react-maas/dist/common/helper/cookie'
import { Cookie } from '@/shared'
import { useDispatch } from 'react-redux'
import { parseUserAgent } from '@/helpers/user-agent'
import { setDeviceInfo } from '@/store/modules/device'

const useSetUserIdentifier = (): void => {
  const dispatch = useDispatch()
  const { loggedIn } = useAuthentication()
  const { uid, id: kakaot_id } = useAppSelector(userInfoSelector)

  useEffect(() => {
    if (getCookie(Cookie.FAKE_MOBILE_TOKEN)) return /* guard */
    const userAgent = navigator.userAgent ?? ''
    setUserIdentifier(userAgent, appBridge as any)
  }, [])

  useEffect(() => {
    const userAgent = navigator.userAgent ?? ''
    dispatch(
      setDeviceInfo(
        parseUserAgent(userAgent, getCookie(Cookie.FAKE_MOBILE_TOKEN))
      )
    )
  }, [dispatch])

  useEffect(() => {
    if (loggedIn) {
      getLogger()
        ?.getInstance()
        ?.setAppUserId(`${uid}` ?? '')
        ?.setKakaoAppKey(process.env.NEXT_PUBLIC_KAKAO_SDK_JAVASCRIPT_KEY ?? '')
        ?.userExAccount({
          kakaot_id,
        })
    }
  }, [loggedIn, uid, kakaot_id])
}

export default useSetUserIdentifier
