import React, { ReactElement } from 'react'
import { WebviewDebugger } from '@kakaomobility/tui-react-maas/dist/maas'
import SwitcherAppHeader from '@/components/debugger/SwitcherAppHeader'
import ExpireTTokenButton from '@/components/debugger/ExpireTTokenButton'
import ExpireKTokenButton from '@/components/debugger/ExpireKTokenButton'
import ExpireAllTokenButton from '@/components/debugger/ExpireAllTokenButton'
import ExpireKRefreshTokenButton from '@/components/debugger/ExpireKRefreshTokenButton'
import ExpireTRefreshTokenButton from '@/components/debugger/ExpireTRefreshTokenButton'
import ExpireAllRefreshTokenButton from '@/components/debugger/ExpireAllRefreshTokenButton'
import UserInfoButton from '@/components/debugger/UserInfoButton'

export default function WebDebugger(): ReactElement | null {
  return (
    <WebviewDebugger>
      <SwitcherAppHeader />
      <ExpireKTokenButton />
      <ExpireTTokenButton />
      <ExpireAllTokenButton />
      <ExpireKRefreshTokenButton />
      <ExpireTRefreshTokenButton />
      <ExpireAllRefreshTokenButton />
      <UserInfoButton />
    </WebviewDebugger>
  )
}
