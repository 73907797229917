import { ClientOnly } from '@kakaomobility/tui-react-maas/dist/maas'
import { setCookie } from '@/helpers/cookieHelper'
import { AdditionalHeaderForAuth } from '@/hooks/useAuthenticationGuard'
import { setUser } from '@/store/modules/user'
import { isLoggedInSelector } from '@/store/selectors/user'
import appBridge from '@kakaomobility/app-bridge'
import React, { useEffect, useRef } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '@/services/api/auth'

function UserInitializerInner(): ReactElement {
  const isLoggedIn = useSelector(isLoggedInSelector)
  const refRetried = useRef<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const executeEffect = async (): Promise<void> => {
      if (!isLoggedIn && !refRetried.current) {
        try {
          if (
            appBridge.renewAccessToken.isSupported() ||
            appBridge.renewTAuthToken.isSupported()
          ) {
            const appendedHeader: AdditionalHeaderForAuth = {}
            if (appBridge.renewAccessToken.isSupported()) {
              const newKToken = await appBridge.renewAccessToken()
              appendedHeader['X-KAKAOT-ACCESS-TOKEN'] = newKToken
              setCookie('X-KAKAOT-ACCESS-TOKEN', newKToken)
            }
            if (appBridge.renewTAuthToken.isSupported()) {
              const newTToken = await appBridge.renewTAuthToken()
              appendedHeader['X-TAUTH-ACCESS-TOKEN'] = newTToken
              setCookie('X-TAUTH-ACCESS-TOKEN', newTToken)
            }
            // 다시 재시도
            const user = await getUser({
              headers: appendedHeader,
            })
            if (!user) throw new Error('not logged in')
            dispatch(setUser(user))
          } else {
            const user = await getUser()
            if (!user) throw new Error('not logged in')
            dispatch(setUser(user))
          }
        } catch (e) {
          console.error(e)
        } finally {
          refRetried.current = false
        }
      }
    }
    executeEffect()
  }, [isLoggedIn, appBridge, refRetried])
  return <></>
}

export default function UserInitializer(): ReactElement {
  return (
    <ClientOnly>
      <UserInitializerInner />
    </ClientOnly>
  )
}
