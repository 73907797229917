import { useCallback } from 'react'
import { resetUser } from '@/store/modules/user'
import { isLoggedInSelector } from '@/store/selectors/user'
import { useAppDispatch, useAppSelector } from '@/store'
import { login, logout } from '@/services/api/auth'

export interface OAuthHandler {
  loggedIn: boolean
  handleLogin: () => void
  handleLogout: () => void
}

function useAuthentication(): OAuthHandler {
  const loggedIn = useAppSelector(isLoggedInSelector)
  const dispatch = useAppDispatch()

  const handleLogin = useCallback(async () => {
    try {
      const res = await login()
      if (typeof window !== 'undefined') window.location.replace(res.authPath)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      const res = await logout()
      if (res.kakaoLogoutPath && typeof window !== 'undefined')
        window.location.replace(res.kakaoLogoutPath)
      dispatch(resetUser())
    } catch (e) {
      console.error(e)
      dispatch(resetUser())
    }
  }, [dispatch])
  return { loggedIn, handleLogin, handleLogout }
}

export default useAuthentication
