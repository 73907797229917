import { customLogger, CustomLoggerInstanceType } from '@/helpers/custom-logger'

/**
 * @description
 * custom logger에서 log 사용성이 있으므로
 * log만 expose 처리를 하기 위해 별도의 hook으로 정의
 */
export const useLogger = (): { log: CustomLoggerInstanceType['log'] } => {
  return customLogger
}
