import { isServerSide } from '@kakaomobility/tui-react-maas'
import * as async_hooks from 'async_hooks'
import { IncomingMessage } from 'http'
import { Cookie } from '@/shared'

interface ServerContextData {
  cookie?: string
  [Cookie.TAUTH_ACCESS_TOKEN]?: string[] | string
  [Cookie.ACCESS_TOKEN]?: string[] | string
}

/**
 * @example https://tech.ssut.me/getting-per-request-context-in-nodejs-with-async_hooks/
 */
class ServerContext {
  private static instance: ServerContext
  static readonly constructionKey = Symbol('ServerContext')
  static getInstance(key: unknown): ServerContext {
    if (this.constructionKey !== key)
      throw new Error(
        'ServerContext should be singleton. Do not attempt to create new instance.'
      )
    if (this.instance) return this.instance
    this.instance = new ServerContext()
    return this.instance
  }

  private readonly contexts = new Map<number, ServerContextData | undefined>()

  constructor() {
    if (!isServerSide()) return this /* guard */
    async_hooks
      ?.createHook?.({
        init: (asyncId, type, triggerAsyncId) => {
          // 새로운 비동기 리소스가 생성되었을 때
          // 만약 부모 asyncId가 이미 context 객체를 가지고 있다면
          // 해당 context 객체를 현재 리소스 asyncId에 할당합니다
          if (this.contexts.has(triggerAsyncId)) {
            this.contexts.set(asyncId, this.contexts.get(triggerAsyncId))
          }
        },
        destroy: (asyncId) => {
          // 메모리 누수를 방지하기 위해 정리하는 작업을 합니다
          this.contexts.delete(asyncId)
        },
      })
      .enable()
  }

  initContext(req?: IncomingMessage): void {
    if (!isServerSide()) return /* guard */
    const asyncId = async_hooks.executionAsyncId()
    this.contexts.set(asyncId, {
      cookie: req?.headers.cookie,
      [Cookie.TAUTH_ACCESS_TOKEN]:
        req?.headers[Cookie.TAUTH_ACCESS_TOKEN] ??
        req?.headers[Cookie.TAUTH_ACCESS_TOKEN.toLowerCase()],
      [Cookie.ACCESS_TOKEN]:
        req?.headers[Cookie.ACCESS_TOKEN] ??
        req?.headers[Cookie.ACCESS_TOKEN.toLowerCase()],
    })
  }

  getContext(): ServerContextData | undefined {
    if (!isServerSide()) return /* guard */
    const asyncId = async_hooks.executionAsyncId()
    return this.contexts.get(asyncId)
  }
}

/* singleton */
const serverContext = ServerContext.getInstance(ServerContext.constructionKey)

export default serverContext
