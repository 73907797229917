import React, { ReactElement } from 'react'
import { TButton } from '@kakaomobility/tui-react-maas/dist/shuttle'
import { useAppSelector } from '@/store'
import { isKakaoTSelector } from '@/store/selectors/device'
import { removeCookie, setCookie } from '@kakaomobility/tui-react-maas'
import { Cookie } from '@/shared'

export default function SwitcherAppHeader(): ReactElement {
  const isMobile = useAppSelector(isKakaoTSelector)
  const onClick = (): void => {
    if (isMobile) {
      removeCookie(Cookie.FAKE_MOBILE_TOKEN)
    } else {
      setCookie(Cookie.FAKE_MOBILE_TOKEN, '1')
    }
    if (typeof window !== 'undefined') window.location.reload()
  }

  return (
    <div>
      <TButton onClick={onClick}>
        {isMobile
          ? '피씨 버전으로 앱 헤더 바꾸기'
          : '모바일 버전으로 앱 헤더 바꾸기'}
      </TButton>
    </div>
  )
}
