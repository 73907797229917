import React, { ReactElement } from 'react'
import ReactQueryDebugger from '@/components/debugger/ReactQueryDebugger'
import BuggerKing from '@/components/debugger/BuggerKing'
import WebDebugger from '@/components/debugger/WebDebugger'
import { CustomLoggerDebugger } from '@/helpers/custom-logger'
import { AppPropsWithParams } from '@/router'
import { useMount } from '@kakaomobility/tui-react-maas/dist/maas'
import { isProductionBuild } from '@/shared'

export default function Debugger({
  appProps,
}: {
  appProps: AppPropsWithParams
}): ReactElement {
  const mount = useMount()
  if (!mount || isProductionBuild()) return <></> /* guard */
  return (
    <>
      <ReactQueryDebugger />
      <BuggerKing />
      <WebDebugger />
      <CustomLoggerDebugger appProps={appProps} />
    </>
  )
}
