import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react'
import styled from '@emotion/styled'
import {
  STYLE_KEEP_WORD,
  FONT_14,
  Dialog,
  TButton,
  ButtonThemeType,
  ButtonSizeType,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import useModalBackHandler from '@/hooks/useModalBackHandler'
import { InjectedModalProps } from '@kakaomobility/tui-react-maas/dist/maas'
import { Dimmer } from '@/components/dialogs/Dimmer'

interface DialogAlertProps extends InjectedModalProps {
  message: ReactNode
  title?: string
  callback?: (...args: any[]) => void | Promise<void>
}

const Styled = {
  body: styled.div`
    padding: 4px 40px 12px;
    text-align: center;
    color: ${TColors.NEUTRAL2};
    ${FONT_14};
    ${STYLE_KEEP_WORD};
    white-space: pre-wrap;
  `,
}

function DialogAlert({
  title = '알림',
  message,
  callback,
  resolve,
  reject,
}: PropsWithChildren<DialogAlertProps>): ReactElement {
  useModalBackHandler(() => {
    resolve('DialogAlert modal closed')
  })
  const handleConfirm = useCallback(() => {
    callback?.()
    resolve('')
  }, [callback])

  return (
    <Dimmer>
      <Dialog
        header={title}
        footer={
          <TButton
            onClick={handleConfirm}
            btnTheme={ButtonThemeType.OUTLINE}
            size={ButtonSizeType.DIALOG}
          >
            확인
          </TButton>
        }
      >
        <Styled.body>
          <>{message}</>
        </Styled.body>
      </Dialog>
    </Dimmer>
  )
}

export default DialogAlert
