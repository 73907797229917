import { LoginResponse, LogoutResponse } from '@/shared'
import instance from '@/services/instance'
import { ShuttleUserInfo } from '@/shared/interfaces'

export const login = async (): Promise<LoginResponse> => {
  return (await instance.get('/login'))?.data
}

export const logout = async (): Promise<LogoutResponse> => {
  return (await instance.get('/logout'))?.data
}

export const getUser = async (params?: any): Promise<ShuttleUserInfo> => {
  return (await instance.get('/user/me', params))?.data
}
