import React from 'react'
import { withClientOnly } from '@kakaomobility/tui-react-maas/dist/maas'
import Script from 'next/script'

export default withClientOnly()(() => {
  const handleOnLoad = (): void => {
    /**
     * @document
     * https://wiki.daumkakao.com/pages/viewpage.action?pageId=1169182083
     */
    ;(window as any)?.buggerking?.init()
  }
  return (
    <Script
      src='https://buggerking.kakao.com/sdk/main.bundle.js'
      onLoad={handleOnLoad}
    />
  )
})
