import BasicApollo from '@/apollo/apollo-proxy/BasicApollo'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

const { link: networkStatusNotifierLink } = createNetworkStatusNotifier()

export default class ApolloServerSide extends BasicApollo {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>

  constructor() {
    super()
    this.apolloClient = this.createApolloClient()
  }

  createApolloClient(): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
      uri: 'http://localhost:3035/api/v1',
      credentials: 'same-origin',
      headers: ((key, value) =>
        key
          ? {
              [key]: value,
            }
          : {})(
        process.env.NEXT_PUBLIC_AUTHORIZATION_KEY,
        process.env.NEXT_PUBLIC_AUTHORIZATION_TOKEN
      ),
    })
    return new ApolloClient({
      ssrMode: true,
      cache: new InMemoryCache(),
      link: networkStatusNotifierLink.concat(httpLink),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    })
  }

  async resetApollo(): Promise<void> {
    await this.apolloClient?.resetStore()
  }

  async query<TData = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, TData>
  ): Promise<ApolloQueryResult<TData>> {
    return await this.apolloClient.query(options)
  }

  getClient(initialState?: any | null): ApolloClient<NormalizedCacheObject> {
    return this.apolloClient
  }
}
