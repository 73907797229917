import { BookingType, Route } from '@/router/routes/index'
import { BackType } from '@/types/pages'
import { BookingVerticalPath } from '@/enums'

interface PageCharterManualQueries {
  backType?: BackType
}

export const PageCharterMaunal = new Route<never, PageCharterManualQueries>(
  '/charterbus/manual'
)

interface PageCharterItemParams {
  charterbusItemId: string
}
interface PageCharterItemQuries {
  dialogType?: string
}
export const PageCharterItem = new Route<
  PageCharterItemParams,
  PageCharterItemQuries
>('/charterbus/:charterbusItemId')

export interface PageCharterBusBookingItemParams {
  bookingId: string
}
export interface PageCharterBusBookingItemQueries {
  vertical?: BookingVerticalPath
  dialogType?: string
  backType?: BackType
}
export const PageCharterBusBookingItem = new Route<
  PageCharterBusBookingItemParams,
  PageCharterBusBookingItemQueries
>('/charterbus/bookings/:bookingId')

export interface PageCharterBusBookingTicketParams {
  bookingId?: string
}
export interface PageCharterBusBookingTicketQueries {
  vertical?: string
}
export const PageCharterBusBookingTicket = new Route<
  PageCharterBusBookingTicketParams,
  PageCharterBusBookingTicketQueries
>('/charterbus/bookings/:bookingId/views/ticket')

export interface PageCharterBusBookingNewParams {
  verticalItemId: string
}
export interface PageCharterBusBookingNewQueries {
  view?: string
  bookingType?: BookingType
  prevPage?: string
}
export const PageCharterBusBookingNew = new Route<
  PageCharterBusBookingNewParams,
  PageCharterBusBookingNewQueries
>('/charterbus/bookings/new/:verticalItemId')

export interface PageCharterBusOrderItemParams {
  orderId?: string
}
export interface PageCharterBusOrderItemQueries {
  vertical?: string
  module?: string
  dialogType?: string
  bookingId?: string
  payAmount?: string
}
export const PageCharterBusOrderItem = new Route<
  PageCharterBusOrderItemParams,
  PageCharterBusOrderItemQueries
>('/charterbus/orders/:orderId')
