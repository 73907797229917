import React, { ReactElement, PropsWithChildren } from 'react'
import useAuthentication from '@/services/hooks/queries/accounts/useAuthentication'
import useAuthenticationGuard from '@/hooks/useAuthenticationGuard'
import { PageError, Route } from '@/router/routes'
import { useRouter } from '@/router/useRouter'
import { CustomNextPage } from '@/router/RouteInterceptor'

interface AuthGuardProps {
  Component: CustomNextPage
}

const disableAuthGuardRoutes: Array<Route<never, never>> = [PageError]
const disableAuthGuardPathnames: string[] = disableAuthGuardRoutes.map(
  ({ pathname }) => pathname
)

export default function AuthGuard({
  Component,
  children,
}: PropsWithChildren<AuthGuardProps>): ReactElement | null {
  const router = useRouter()
  const { authRequired } = Component
  const { loggedIn } = useAuthentication()
  useAuthenticationGuard(
    !disableAuthGuardPathnames.includes(router.pathname),
    authRequired
  )
  if (authRequired && !loggedIn) return null /* guard */
  return <>{children}</>
}
