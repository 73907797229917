import BasicApollo from '@/apollo/apollo-proxy/BasicApollo'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import {
  getAppInfo,
  renewAccessToken,
  renewTAuthToken,
} from '@kakaomobility/app-bridge'
import { setCookie } from '@/helpers/cookieHelper'
import { Cookie } from '@/shared'
import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types'
import { QueryOptions } from '@apollo/client/core/watchQueryOptions'
import _merge from 'lodash/merge'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

const { link: networkStatusNotifierLink } = createNetworkStatusNotifier()

export default class ApolloClientSide extends BasicApollo {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>

  constructor() {
    super()
    this.apolloClient = this.createApolloClient()
  }

  createApolloClient(): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
      uri: `${process.env.NEXT_PUBLIC_PUBLIC_URI}/api/v1`,
      credentials: 'same-origin',
      headers: ((key, value) =>
        key
          ? {
              [key]: value,
            }
          : {})(
        process.env.NEXT_PUBLIC_AUTHORIZATION_KEY,
        process.env.NEXT_PUBLIC_AUTHORIZATION_TOKEN
      ),
    })

    const retryLink = new RetryLink({
      delay: {
        max: 100000,
      },
      attempts: {
        max: 5,
        retryIf: async (error, operation) => {
          if (error.statusCode === 401) {
            try {
              if (renewAccessToken.isSupported()) {
                setCookie(Cookie.ACCESS_TOKEN, await renewAccessToken())
              } else {
                console.error(
                  `renewAccessToken is not supported: ${JSON.stringify(
                    getAppInfo()
                  )}`
                )
              }
              if (renewTAuthToken.isSupported()) {
                setCookie(Cookie.TAUTH_ACCESS_TOKEN, await renewTAuthToken())
              } else {
                console.error(
                  `renewTAuthToken is not supported: ${JSON.stringify(
                    getAppInfo()
                  )}`
                )
              }
              return (
                renewAccessToken.isSupported() || renewTAuthToken.isSupported()
              )
            } catch (e) {
              console.error(e)
              return false
            }
          }
          return false
        },
      },
    })

    return new ApolloClient({
      ssrMode: false,
      cache: new InMemoryCache(),
      link: retryLink.concat(networkStatusNotifierLink.concat(httpLink)),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    })
  }

  async resetApollo(): Promise<void> {
    await this.apolloClient?.resetStore()
  }

  async query<TData = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, TData>
  ): Promise<ApolloQueryResult<TData>> {
    return await this.apolloClient.query(options)
  }

  getClient(initialState?: any | null): ApolloClient<NormalizedCacheObject> {
    if (initialState) {
      const existingCache = this.apolloClient?.extract()
      const data = _merge(initialState, existingCache)
      this.apolloClient.cache.restore(data)
    }
    return this.apolloClient
  }
}
