import { Route } from '@/router/routes/index'
import { PageShuttleNewView, ShuttleDialogType } from '@/enums'
import { RentType, RouteType, ShuttleGrade } from '@/__generated__/globalTypes'
import { LocationType } from '@/containers/modals/LocationSearchModal'

export interface PageShuttleMainQueries {
  categoryId?: string | number
  page?: string | number
  sort?: 'PRICE_DESC' | 'REGISTER_ASC'
}
export const PageShuttleMain = new Route<never, PageShuttleMainQueries>(
  '/shuttles'
)

export interface PageShuttleFilterTagsQueries {
  tag?: string
}
export const PageShuttleFilterTags = new Route<
  never,
  PageShuttleFilterTagsQueries
>('/shuttles/tags')

interface PageShuttleItemParams {
  shuttleItemId: string
}
interface PageShuttleItemQueries {
  sentFrom?: string /* 앱투앱에서 어떤 앱에서 해당 페이지를 열었는지 여부를 확인하기 위한 query */
  dialogType?: string
}
export const PageShuttleItem = new Route<
  PageShuttleItemParams,
  PageShuttleItemQueries
>('/shuttles/:shuttleItemId')

export interface PageShuttleNewQueries {
  view?: PageShuttleNewView | string
  departureAdr?: string
  departureLat?: string
  departureLng?: string
  departureTitle?: string
  arrivalAdr?: string
  arrivalLat?: string
  arrivalLng?: string
  arrivalTitle?: string
  rentType?: RentType | string
  routeType?: RouteType | string
  seater?: number | string
  shuttleGrade?: ShuttleGrade | string
  dialogType?: ShuttleDialogType
  locationType?: LocationType | null
  open_type?: string
  code?: string
  originDt?: string
  destDt?: string
}
export const PageShuttleNew = new Route<never, PageShuttleNewQueries>(
  '/shuttles/new'
)
