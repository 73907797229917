import { Route } from '@/router/routes/index'

export const PageFestivalHome = new Route<never, never>('/festivals')

interface PageFestivalItemQueries {
  festivalName?: string
}
export const PageFestivalItem = new Route<never, PageFestivalItemQueries>(
  '/festivals/festival'
)
